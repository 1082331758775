import { Fragment, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Textfield from "../../../components/form-components/text-field";
import GroupMembers from "./GroupMembers";
import { useDroppable } from "@dnd-kit/core";
import { i18n } from "../../../translate/i18n";

const ClassGroup = ({
  group,
  isOpen,
  isMainRowOpen,
  index,
  setIsOpen,
  handleGroupNameChange,
}) => {
  const { setNodeRef } = useDroppable({
    id: group.id,
  });

  //To improve performance, just updating the main hadler onBlur event (handleGroupNameChange), using this state
  const [currentGroupName, setCurrentGroupName] = useState({
    id: null,
    name: null,
  });

  return (
    <Fragment>
      <TableRow
        key={group.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        ref={setNodeRef}
        style={{
          backgroundColor: "#F7F9FC",
          border: "1px solid #E0E0E0",
        }}
      >
        <TableCell
          component="th"
          scope="row"
          colSpan={5}
          sx={{ padding: 0, paddingLeft: 10 }}
        >
          <Collapse in={isMainRowOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: "flex",
                gap: 4,
                width: "100%",
                alignItems: "center",
                paddingY: 2,
              }}
            >
              <IconButton
                sx={{ border: 1, padding: 0 }}
                onClick={() =>
                  setIsOpen((prevState) => {
                    const newState = [...prevState];
                    newState[index] = !newState[index];
                    return newState;
                  })
                }
              >
                {isOpen[index] ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
              <Box>
                <Textfield
                  name="className"
                  value={
                    currentGroupName.id === group.id
                      ? currentGroupName.name
                      : group.name
                  }
                  onChange={(event) =>
                    setCurrentGroupName({
                      id: group.id,
                      name: event.target.value,
                    })
                  }
                  onBlur={() => {
                    handleGroupNameChange(
                      currentGroupName.id,
                      currentGroupName.name
                    );
                    setCurrentGroupName({ id: null, name: null });
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "none", // remove border
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  inputProps={{
                    style: { fontWeight: "bold" },
                    size:
                      currentGroupName.id === group.id
                        ? currentGroupName.name.length
                        : group.name.length,
                  }}
                />
              </Box>
              <Typography sx={{ marginLeft: 3 }}>
                {`( ${group.students?.length} ${i18n.t("others.student")} )`}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5} sx={{ padding: 0, border: "0px" }}>
          <Collapse in={isOpen[index] && isMainRowOpen} timeout="auto">
            <GroupMembers {...group} index={index} />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default ClassGroup;
