import { Grid, Typography, IconButton } from "@mui/material";
import axios from "axios";
import HandleUrl from "../../../utils/HandleUrl";
import Select from "../../../components/form-components/select";
import { i18n } from "../../../translate/i18n";

import DateTimePicker from "../../../components/form-components/date-time-picker";
import { useKeycloak } from "@react-keycloak/web";
import ButtonWithTitle from "../../../components/styleButton/styleButton";
import DisciplinaModal from "./forms-fields/modal";
import CloseIcon from "@mui/icons-material/Close";
import { fetchAllItems } from "../../../utils/functions/formCRUDFunctions";
import { useState, useEffect } from "react";

export default function HiringDetails({
   values,
   isProfessor,
   setIsProfessor,
   selectedDisciplinas,
   setSelectedDisciplinas,
   selectedDepartment,
   setSelectedDepartment,
}) {
   const [departmentsIncommingData, setDepartmentsIncommingData] = useState([]);
   const [subjectsIncommingData, setSubjectsIncommingData] = useState([]);
   const [rolesIncommingData, setRolesIncommingData] = useState([]);
   const [CategoriesIncommingData, setCategoriesIncommingData] = useState([]);
   const [contractTypeIncommingData, setContractTypeIncommingData] = useState({});
   const { keycloak } = useKeycloak();
   const [isDisabled, setIsDisabled] = useState(HandleUrl());
   const [totalPages, setTotalPages] = useState();
   const [page, setPage] = useState(1);
   const [isDisciplinaModalOpen, setIsDisciplinaModalOpen] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [itemsOffset, setItemsOffset] = useState(0);

   const apiUrl = process.env.REACT_APP_API_URL;

   const fetchAllSubjects = fetchAllItems(keycloak);

   const getList = async (type, set) => {
      try {
         const response = await axios.get(`${apiUrl}/${type}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
         });
         set(response.data?.data ? response.data.data : response.data);
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      getList("departments", setDepartmentsIncommingData);
      getList("contract-type", setContractTypeIncommingData);
      getList("employee-role", setRolesIncommingData);
      getList("employee-category", setCategoriesIncommingData);
      getList("subject", setSubjectsIncommingData);
   }, []);

   const handleRoleChange = (event) => {
      const selectedRoleValue = event.target.value;
      const selectedRoleObject = rolesIncommingData.find((role) => role.id === selectedRoleValue);
      const isProfessorRole = selectedRoleObject?.name === "Professor(a)";
      setIsProfessor(isProfessorRole);
   };

   const saveDisciplineNames = () => {
      console.log(
         "Salvando nomes das disciplinas:",
         selectedDisciplinas.map((disciplina) => disciplina.name)
      );
   };

   const handleRemoveDisciplina = (disciplinaToRemove) => {
      const updatedDisciplinas = selectedDisciplinas.filter((disciplina) => disciplina?.id !== disciplinaToRemove?.id);
      setSelectedDisciplinas(updatedDisciplinas);
      saveDisciplineNames();
   };

   const handleAddDisciplinas = () => {
      setIsDisciplinaModalOpen(true);
      saveDisciplineNames();
   };

   const saveDepartmentNames = () => {
      console.log(
         "Salvando nomes dos departamentos:",
         selectedDepartment.map((depart) => depart.name)
      );
   };

   const handleRemoveDepartment = (department) => {
      const updatedDeparts = selectedDepartment.filter((currentDepart) => currentDepart?.id !== department?.id);
      setSelectedDepartment(updatedDeparts);
      saveDepartmentNames();
   };

   return (
      <Grid container>
         <Grid item xs={12} marginBottom={2}>
            <Typography sx={{ color: "#003B76" }} variant="h3">
               {i18n.t("employee.hiringDetails")}
            </Typography>
         </Grid>

         <Grid item container spacing={3}>
            <Grid item xs={5} style={{ marginTop: itemsOffset }}>
               <Select
                  name="contractType.id"
                  options={contractTypeIncommingData}
                  label={i18n.t("others.contractType")}
                  disabled={isDisabled}
               />
            </Grid>

            <Grid item xs={5} style={{ marginTop: itemsOffset }}>
               <Select
                  name="employeeRoles[0].id"
                  options={rolesIncommingData}
                  defaultValue={values.employeeRoles?.[0]?.id}
                  label={i18n.t("others.role")}
                  disabled={isDisabled}
                  onChange={handleRoleChange}
               />
            </Grid>
            <Grid item xs={5} style={{ marginBottom: 8 }}>
               <Select
                  name="employeeCategories[0].id"
                  options={CategoriesIncommingData}
                  label={i18n.t("others.category")}
                  disabled={isDisabled}
               />
            </Grid>
            <Grid item xs={2.3}>
               <DateTimePicker name="startFunction" label={i18n.t("others.StartFunctions")} disabled={isDisabled} />
            </Grid>
            <Grid item xs={2.3}>
               <DateTimePicker name="endFunction" label={i18n.t("others.EndFunctions")} disabled={isDisabled} />
            </Grid>
            <Grid item xs={5}>
               <Grid item container xs={12}>
                  <Grid item xs={12}>
                     <label>
                        <p
                           style={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "#42474B",
                              margin: "0px",
                              border: "none",
                           }}
                        >
                           {i18n.t("others.department")}
                        </p>
                     </label>
                  </Grid>
               </Grid>

               <Grid item xs={12}>
                  {
                     <ButtonWithTitle
                        title={
                           selectedDepartment.length > 0
                              ? `${i18n.t("department.addedDeparts")}`
                              : `${i18n.t("department.addDepart")}`
                        }
                        onClick={() => setIsModalOpen(true)}
                        disabled={isDisabled}
                     />
                  }
                  <Grid container spacing={1}>
                     {(selectedDepartment.length > 0 ? selectedDepartment : values.departments)?.map((department) => (
                        <Grid item key={department.id}>
                           <div
                              style={{
                                 display: "flex",
                                 alignItems: "center",
                                 marginRight: 2,
                                 marginBottom: 1,
                                 background: "#E9ECF5",
                                 padding: "1px",
                                 marginTop: "8px",
                              }}
                           >
                              <Typography key={department.id}>{department.name}</Typography>
                              <IconButton size="small" onClick={() => handleRemoveDepartment(department)}>
                                 {!isDisabled && <CloseIcon />}
                              </IconButton>
                           </div>
                        </Grid>
                     ))}
                  </Grid>
               </Grid>

               <DisciplinaModal
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  onConfirm={(selectedDeparts) => {
                     setSelectedDepartment(selectedDeparts);
                     saveDepartmentNames();
                     setIsModalOpen(false);
                  }}
                  title={i18n.t("others.department")}
                  data={departmentsIncommingData}
                  type="departments"
               />
            </Grid>

            <Grid item xs={4.8}>
               {(isProfessor || values.employeeRoles?.[0]?.name === "Professor(a)") && (
                  <>
                     <Grid item xs={12}>
                        <label>
                           <p
                              style={{
                                 fontSize: "13px",
                                 fontWeight: "600",
                                 color: "#42474B",
                                 margin: "0px",
                                 border: "none",
                              }}
                           >
                              {i18n.t("others.disciplina")}
                           </p>
                        </label>
                     </Grid>
                     <Grid>
                        <ButtonWithTitle
                           title={selectedDisciplinas.length > 0 ? "Disciplinas Adicionadas" : "Adicionar disciplinas"}
                           onClick={handleAddDisciplinas}
                           disabled={isDisabled}
                        />
                     </Grid>{" "}
                  </>
               )}
               <Grid container spacing={1}>
                  {selectedDisciplinas.length === 0 ? (
                     <>
                        {values.subjects &&
                           values.subjects.map((disciplina) => (
                              <Grid item key={disciplina.id}>
                                 <div
                                    style={{
                                       display: "flex",
                                       alignItems: "center",
                                       marginRight: 2,
                                       marginBottom: 1,
                                       background: "#E9ECF5",
                                       padding: "1px",
                                       marginTop: "8px",
                                    }}
                                 >
                                    <Typography key={disciplina.id} disabled={isDisabled}>
                                       {disciplina.name}
                                    </Typography>
                                    <IconButton size="small" onClick={() => handleRemoveDisciplina(disciplina)}>
                                       <CloseIcon />
                                    </IconButton>
                                 </div>
                              </Grid>
                           ))}
                     </>
                  ) : (
                     <>
                        {selectedDisciplinas.map((disciplina) => (
                           <Grid item key={disciplina.id}>
                              <div
                                 style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: 2,
                                    marginBottom: 1,
                                    background: "#E9ECF5",
                                    padding: "1px",
                                    marginTop: "8px",
                                 }}
                              >
                                 <Typography key={disciplina.id}>{disciplina.name}</Typography>
                                 <IconButton size="small" onClick={() => handleRemoveDisciplina(disciplina)}>
                                    <CloseIcon />
                                 </IconButton>
                              </div>
                           </Grid>
                        ))}
                     </>
                  )}
               </Grid>
            </Grid>

            <DisciplinaModal
               open={isDisciplinaModalOpen}
               onClose={() => setIsDisciplinaModalOpen(false)}
               onConfirm={(selectedDisciplinas) => {
                  setSelectedDisciplinas(selectedDisciplinas);
                  saveDisciplineNames();
                  setIsDisciplinaModalOpen(false);
               }}
               data={subjectsIncommingData}
               title={i18n.t("others.disciplina")}
               type="subject"
            />
         </Grid>
      </Grid>
   );
}
