import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { i18n } from "../../translate/i18n";
import FallBackLoader from "../FallBackComponents/FallBackLoader";
import { Grid, IconButton } from "@mui/material";
import PropTypes from 'prop-types';
export const MessageDialog = ({
  id,
  open,
  handleClose,
  message,
  isLoading = false,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Grid container justifyContent="flex-end" sx={{ marginBottom: 2.5 }}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 0, right: 0, marginRight: 2 }}
          >
            <img
              src="/icon/close.svg"
              alt="Close"
              style={{ width: 35.96, height: 35.96 }}
            />
          </IconButton>
        </Grid>
        <div>
          {i18n.t(id ? "messages.updateTitle" : "messages.createTitle")}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FallBackLoader isLoading={isLoading} />
          {!isLoading && message}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
MessageDialog.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
};
