import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const StyledDashedButton = styled(Button)(
  ({  titleColor, isDisabled }) => ({
    color: titleColor || "#FFC107",
    padding: "5px 35px",
    width: "80%",
    height: "25px",
    border: `2px solid ${titleColor || "#FFC107"}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color 0.3s, color 0.3s",

    "&:hover": {
      color: "#FFFFFF",
      border: `2px solid ${titleColor || "#FFC107"}`,
    },

    // Adiciona estilos para quando o botão está desabilitado
    ...(isDisabled && {
      color: "#A9A9A9", // Cor cinza para texto
      border: "2px dashed #A9A9A9", // Cor cinza para a borda
      pointerEvents: "none", // Impede eventos de clique quando desabilitado
    }),
  })
);

const ButtonWithTitle = ({ title, titleColor, onFileChange, ...props }) => {
  const fileInputRef = React.useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleClick = () => {
    if (onFileChange && !isButtonDisabled) {
      setIsButtonDisabled(true); // Desabilita o botão após clicar
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <StyledDashedButton
        titleColor={titleColor}
        isDisabled={isButtonDisabled}
        onClick={handleClick}
        {...props}
      >
        {title}
      </StyledDashedButton>
      <input
        ref={fileInputRef}
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          if (onFileChange && file) {
            onFileChange(file);
          }
        }}
        style={{ display: "none" }}
      />
    </>
  );
};

ButtonWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  onFileChange: PropTypes.func,
};
export default ButtonWithTitle;
