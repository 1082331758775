import { Box, Pagination } from "@mui/material";
import React, { useState } from "react";
import PropTypes from 'prop-types';
const CustomPagination = ({ totalPages, setPage, page }) => {
  const [selectedPage, setSelectedPage] = useState(page || 1);

  const handlePageChange = (event, value) => {
    setSelectedPage(value);
    setPage(value);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginY: 2 }}>
      <Pagination
        defaultPage={parseInt(page || 1)}
        variant="outlined"
        count={totalPages}
        color="secondary"
        page={selectedPage}
        onChange={handlePageChange}
        sx={{
          borderBottom: "none",
          "& .Mui-selected": {
            backgroundColor: "#FFC107",
            color: "white",
            borderColor: "#FFC107",
            "&:hover": {
              backgroundColor: "#FFC107",
              color: "white",
            },
          },
          "& .MuiPaginationItem-root": {
            color: "#FFC107",
            backgroundColor: "white",
            borderColor: "#FFC107",
            "&.Mui-selected": {
              backgroundColor: "#FFC107",
              color: "white",
            },
            "&:hover": {
              backgroundColor: "#FFC107",
              color: "white",
            },
          },
        }}
      />
    </Box>
  );
};
CustomPagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number,
};

export default CustomPagination;
