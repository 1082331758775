import React, { useState } from 'react';
import './calender.css'; // Import the CSS file
import { isToday, addMonths, subMonths } from 'date-fns';
import { Grid } from '@mui/material';

export default function CustomCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date());

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  const renderCalendar = () => {
    const calendar = [];
    const firstDayOfMonth = new Date(year, month, 1);
    const startingDayOfWeek = firstDayOfMonth.getDay(); // 0 for Sunday, 1 for Monday, etc.

    // Render empty cells for days before the first day of the month
    for (let i = 0; i < startingDayOfWeek; i++) {
      calendar.push(<div key={`empty-${i}`} className="empty-day" />);
    }

    // Render the days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const isCurrentDate = isToday(new Date(year, month, day));
      const dayClass = isCurrentDate ? 'calendar-day current-day' : 'calendar-day';
      calendar.push(
        <div key={day} className={dayClass}>
          {day}
        </div>
      );
    }

    return calendar;
  };

  const goToNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const goToPreviousMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  return (
    <Grid className="calendar-container">
      <Grid className="calendar-header">
        <span className="month-year">
          {new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(currentDate)}
        </span>
        <Grid className="header-buttons">
          <button onClick={goToPreviousMonth} className="arrow-button">&lt;</button>
          <button onClick={goToNextMonth} className="arrow-button">&gt;</button>
        </Grid>
      </Grid>
      <Grid className="calendar-weekdays">
        <Grid className="weekday">Sun</Grid>
        <Grid className="weekday">Mon</Grid>
        <Grid className="weekday">Tue</Grid>
        <Grid className="weekday">Wed</Grid>
        <Grid className="weekday">Thu</Grid>
        <Grid className="weekday">Fri</Grid>
        <Grid className="weekday">Sat</Grid>
      </Grid>
      <Grid className="calendar-days">{renderCalendar()}</Grid>
    </Grid>
  );
}
