import { createTheme } from "@mui/material/styles";

const GlobalVariantsChanges = createTheme({
  typography: {
    h1: {
      fontSize: 20,
      fontWeight: 600,
      color: "#003B76",
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "18.77px",
      color: "#42474B",
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "normal",
      color: "#42474B",
    },
    fontFamily: ["Work Sans", "sans-serif"].join(","),
    color: "#42474B",
    fontWeight: 500,
  },
  palette: {
    secondary: {
      main: "#FFC107",
    },
    warning: {
      main: "#FFC107",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: "#E9ECF5",
          borderWidth: "1px",
          minheight: "40px",
          "&.Mui-focused": {
            outline: "none",
            borderColor: "#C3CAD9",
          },
          "&:hover": {
            borderColor: "#C3CAD9", // set to the same color as the default state
          },
          "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#C3CAD9", // set to the same color as the default state
            },
            "&:hover fieldset": {
              borderColor: "#C3CAD9", // set to the same color as the default state
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.MuiCheckbox-root": {
            borderStyle: "solid",
            borderColor: "#E9ECF5",
          },
          "&.Mui-checked": {
            //backgroundColor: "#FFC107", // Change this to your desired background color
            color: "#FFC107", // Change this to your desired checkmark color
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderStyle: "solid",
          borderWidth: "1px",
          padding: "2px",
          color: "#FFC107",
          backgroundColor: "#fff",
          borderColor: "#FFC107",
          "&:hover": {
            color: "#fff",
            backgroundColor: "#FFC107",
          },

          "&.Mui-disabled": {
            backgroundColor: "#E9ECF5",
            color: "#C3CAD9",
            border: "1px solid #C3CAD9",
          },
        },
      },
    },
    //For all table cells' content
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: "500",
        },
      },
    },
  },
  // components: {
  //     MuiTypography: {
  //         defaultProps: {
  //             variantMapping: {
  //                 h1: 'h2',
  //                 h2: 'h2',
  //                 h3: 'h2',
  //                 h4: 'h2',
  //                 h5: 'h2',
  //                 h6: 'h2',
  //                 subtitle1: 'h2',
  //                 subtitle2: 'h2',
  //                 body1: 'span',
  //                 body2: 'span',
  //             },
  //             disableRipple: true
  //         },
  //     },
  // },
});

export default GlobalVariantsChanges;
