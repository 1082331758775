import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const AutoSearch = ({
  placeholder,
  iconPosition = "start",
  sx,
  inputSx,
  onClear,
  searchEndpoint,
  search,
  setSearch,
}) => {
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;

    if (typeof setSearch === "function") {
      setSearch(searchTerm);
    }
  };

  const handleClear = () => {
    if (typeof onClear === "function") {
      onClear();
    }

    if (typeof setSearch === "function") {
      setSearch("");
    }
  };

  useEffect(() => {
    if (searchEndpoint && typeof searchEndpoint === "function") {
      const performSearch = async () => {
        try {
          await searchEndpoint(search);
        } catch (error) {}
      };

      performSearch();
    }
  }, [search, searchEndpoint]);

  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      size="small"
      sx={{ ...sx }}
      InputProps={{
        startAdornment: (
          <InputAdornment position={iconPosition}>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: search && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear} disableRipple>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
        sx: { ...inputSx },
      }}
      value={search}
      onChange={handleSearchChange}
      placeholder={placeholder}
    />
  );
};

AutoSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  iconPosition: PropTypes.oneOf(["start", "end"]),
  sx: PropTypes.object,
  inputSx: PropTypes.object,
  onClear: PropTypes.func,
  searchEndpoint: PropTypes.func,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default AutoSearch;
