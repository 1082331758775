
export default function HandleUrl() {
    const urlString = window.location.href;
    let urlSubstrings = urlString.split('?')[1];
    if (urlSubstrings) {
        let stringParams = new URLSearchParams(urlSubstrings);

        for (let pair of stringParams.entries()) {
            let key = pair[0];
            let value = pair[1];
            if (key === 'disabled') {
                return value;
            }
        }
    }

    return false; // Retorna false como padrão quando 'disabled' não está presente na URL
}
