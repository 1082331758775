import { Grid, Step, StepButton, Stepper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SquareIcon from '@mui/icons-material/Square';
import { Formik } from "formik";
import IssueDocumentSEAnalyse from "../issueDocumentsSecretaria/IssueDocumentSEAnalyse";
import HistoryAnalyse from "../../enroll-student/form-analysis/HistoryAnalyse";

const steps = ["Pagamento", "Histórico"];

const IssueDocumentAsAnalyseSecretaty = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [isScrolled, setIsScrolled] = useState(false);
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    const handleScroll = () => {
        setIsScrolled(window.scrollY > 0);
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const stepStyles = {
        "& .Mui-active": {
            color: "#FFC107",
        },
    };

    return (
        <Grid className="center-container">
            <Typography variant="h6" style={{
                position: "fixed",
                marginTop: "-60px",

            }}
            >
                Pedido de Emissão de {isScrolled && <br />} Documento
            </Typography>
            <Grid className="mother-flex" sx={{ marginTop: "40px"}}>
                <Grid className="left-side">
                    <Stepper orientation='vertical' nonLinear activeStep={activeStep} sx={stepStyles}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton
                                    sx={{ color: '#C2D6E5' }}
                                    icon={<SquareIcon />}
                                    onClick={handleStep(index)}
                                >
                                    <p className='step-title'>{label}</p>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>

             

                <Grid className="right-side">
                    <Formik>
                        {({ values }) => (
                            <div>
                                {activeStep === 0 && (
                                    <>
                                        <IssueDocumentSEAnalyse />
                                    </>
                                )}
                                {activeStep === 1 && (
                                    <>
                                        <HistoryAnalyse values={values} />
                                    </>
                                )}
                            </div>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IssueDocumentAsAnalyseSecretaty;
