import { Box, Grid } from "@mui/material";
import { i18n } from "../../translate/i18n";
import PropTypes from 'prop-types';
const FallBackCard = ({ data =[], isLoading }) => {
  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  return (
    <>
      {(!data || data?.length === 0 || isEmpty(data)) && !isLoading && (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // This will center the item vertically
            height: "50vh", // This will make the Grid take up the full height of the viewport
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
          >
            <div
              style={{
                color: "#42474B",
                fontSize: "20px",
                textAlign: "center",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#E9ECF5",
                margin: "20px auto",
                maxWidth: "300px",
              }}
            >
              {i18n.t("messages.notFound")}
            </div>
          </Box>
        </Grid>
      )}
    </>
  );
};
FallBackCard.propTypes = {
  data: PropTypes.array, // You can specify the type here, in this case, an array
  isLoading: PropTypes.bool.isRequired, // Required boolean prop
};
export default FallBackCard;
