import * as React from "react";
import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { i18n } from "../../translate/i18n";
import AnotherCustomTable from "../../components/AnotherCustomTable/AnotherCustomTable";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import ProfileImage from "../../components/perfilImage/profileImage";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";

export default function StudentListing() {
   const [activeStep, setActiveStep] = useState(0);
   const [loadingProfile, setLoadingProfile] = useState(true);
   const apiUrl = process.env.REACT_APP_API_URL;
   const { keycloak } = useKeycloak();
   const [studentData, setStudentData] = useState(null);
   const [profileData, setProfileData] = useState(null);
   const [files, setFiles] = useState({});

   const customStepperStyle = {
      padding: "10px",
      margin: "0",
      marginLeft: "-30px",
   };

   const selectStep = (step) => {
      setActiveStep(step);
   };
   const CustomConnector = () => {
      return null;
   };
   const CustomStepIcon = () => {
      return null;
   };
   const customStepLabelStyle = {
      background: "#EFEFEF",
      fontWeight: "bold",
   };
   const selectedStepStyle = {
      background: "white",
   };
   const selectedStepLabelStyle = {
      color: "yellow",
   };

   const fetchStudentByUserId = async () => {
      try {
         const userId = keycloak.idTokenParsed.sub;
         
         const studentData = await fetchStudentData(userId);
         const profileData = await fetchProfileData(studentData.id);
   
         updateStudentAndProfileData(studentData, profileData);
         
         return { studentData, profileData };
      } catch (error) {
         console.error("Erro ao buscar estudante pelo ID do usuário:", error);
         return null;
      }
   };
   
   const fetchStudentData = async (userId) => {
      const response = await axios.get(`${apiUrl}/students/user-account/${userId}`, {
         headers: {
            Authorization: "Bearer " + keycloak.token,
         },
      });
      return response.data;
   };
   
   const fetchProfileData = async (studentId) => {
      const response = await axios.get(`${apiUrl}/students/student-profile/${studentId}`, {
         headers: {
            Authorization: "Bearer " + keycloak.token,
         },
      });
      return response.data;
   };
   
   const updateStudentAndProfileData = (studentData, profileData) => {
      setStudentData(studentData);
      setProfileData(profileData);
      setLoadingProfile(false);
   };
   
   useEffect(() => {
      fetchStudentByUserId();
   }, []);
   const generateDataArray = (config, profileData) => {
      return config.map(item => ({
         column1: item.label,
         column2: item.value(profileData) || "N/A"
      }));
   };
   
   // Configuração para dados acadêmicos
   const academicConfig = [
      { label: "Curso", value: profileData => profileData?.academicDetails?.course },
      { label: "Especialidade", value: profileData => profileData?.academicDetails?.speciality },
      { label: "Ano", value: profileData => profileData?.academicDetails?.year },
      { label: "Semestre", value: profileData => profileData?.academicDetails?.semester },
      { label: "Ano académico de 1ª inscrição na faculdade", value: profileData => profileData?.academicDetails?.college_enrollment_year },
      { label: "Reprovou de anos?", value: profileData => profileData?.academicDetails?.reprova_anos },
      { label: "Perdeu anos sem ser por reprovação?", value: profileData => profileData?.academicDetails?.perdeu_anos_sem_reprovacao },
      { label: "Disciplinas em atraso?", value: profileData => profileData?.academicDetails?.disciplinas_em_atraso }
   ];
   
   // Configuração para dados biográficos
   const personalConfig = [
      { label: "Tipo documento", value: profileData => profileData?.personalInformations?.documentType },
      { label: "Número documento", value: profileData => profileData?.personalInformations?.documentNumber },
      { label: "Nome", value: profileData => profileData?.personalInformations?.name },
      { label: "Data Nascimento", value: profileData => profileData?.personalInformations?.birthdate },
      { label: "Função exercida, só deverá indicar as que forem remuneradas.", value: profileData => profileData?.aditionalDetails?.currentRemuneratedFunction },
      { label: "Trabalha em algum organismo de estado?", value: profileData => profileData?.aditionalDetails?.worksInGovernmentAgency ? "Sim" : "Não" },
      { label: "Ficheiros", value: profileData => profileData?.aditionalDetails?.files ? "Sim" : "Não" }
   ];
   
   // Configuração para dados adicionais
   const additionalConfig = [
      { label: "Estudas exclusivamente?", value: profileData => profileData?.aditionalDetails?.fullTimeStudent ? "Sim" : "Não" },
      { label: "Telefone", value: profileData => profileData?.aditionalDetails?.phone },
      { label: "Profissão", value: profileData => profileData?.aditionalDetails?.profession },
      { label: "O grau profissional foi-lhe conferido por alguma escola?", value: profileData => profileData?.aditionalDetails?.professionalDegreeAwardedBySchool ? "Sim" : "Não" },
      { label: "Função exercida, só deverá indicar as que forem remuneradas.", value: profileData => profileData?.aditionalDetails?.currentRemuneratedFunction },
      { label: "Trabalha em algum organismo de estado?", value: profileData => profileData?.aditionalDetails?.worksInGovernmentAgency ? "Sim" : "Não" },
      { label: "Ficheiros", value: profileData => profileData?.aditionalDetails?.files ? "Sim" : "Não" }
      // Adicione mais configurações conforme necessário
   ];
   
   // Utilize a função generateDataArray para gerar os arrays de dados
   const dataForTables = generateDataArray(academicConfig, profileData);
   const dataForTablea = generateDataArray(personalConfig, profileData);
   const dataForTable = generateDataArray(additionalConfig, profileData);
   
   console.log("Dados acadêmicos:", studentData?.academicDetails);

   const uploadFiles = async (id = studentData?.enroll?.id) => {
      try {
         const formData = new FormData();
         formData.append("entityID", id);
         formData.append("ProfilePic", files["ProfilePic"]);

         const response = await axios.post(`${apiUrl}/enrollAttachment`, formData, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },

            "Content-Type": "multipart/form-data",
         });
         console.log("Resposta da Requisição de Upload:", response);

      } catch (error) {
         console.error("Erro ao fazer upload do arquivo:", error);
      }
   };
   return (
      <>
         {" "}
         <FallBackLoader isLoading={loadingProfile} />
         {!loadingProfile && (
            <Grid item sx={{ backgroundColor: "white", padding: "10px", paddingTop: "30px" }}>
               <Grid container display={"flex"} sx={{ marginLeft: "20px" }}>
                  <Grid>
                     <ProfileImage setFiles={setFiles} onChange={uploadFiles} id={studentData?.enroll?.id} />
                  </Grid>
                  <Grid>
                     <Typography
                        sx={{
                           fontFamily: "Work Sans",
                           fontSize: 28,
                           fontWeight: 600,
                           lineHeight: "33px",
                           letterSpacing: "0em",
                           textAlign: "left",
                           marginLeft: "10px",
                        }}
                     >
                        {profileData?.personalInformations?.name || "Nome do Aluno"}
                     </Typography>

                     <Typography
                        variant="h1"
                        sx={{
                           fontFamily: "Work Sans",
                           fontSize: 13,
                           color: "#8893A9", // Change text color to black
                           marginTop: "10px", // Add top margin for spacing
                           marginLeft: "10px",
                        }}
                     >
                        {profileData?.academicDetails?.course}
                     </Typography>
                     <Typography
                        variant="h1"
                        sx={{
                           fontFamily: "Work Sans",
                           fontSize: 13,
                           marginTop: "10px",
                           marginLeft: "10px",
                           color: "#8893A9",

                        }}
                     >
                        {profileData?.academicDetails?.speciality }
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         )}
         <br />
         <Grid sx={{ backgroundColor: "white", padding: "80px", paddingTop: "30px" }}>
            <Grid item xs={10} style={customStepperStyle}>
               <Stepper
                  style={{ width: "600px", cursor: "pointer", paddingLeft: "0", paddingRight: "0" }}
                  activeStep={activeStep}
                  alternativeLabel
                  connector={<CustomConnector />}
               >
                  {["Dados académicos", "Dados biográficos", "Dados adicionais"].map((label, index) => (
                     <Step sx={{ paddingLeft: "0", paddingRight: "0" }} key={index}>
                        <StepLabel
                           StepIconComponent={CustomStepIcon}
                           onClick={() => selectStep(index)}
                           style={{
                              ...customStepLabelStyle,
                              ...(activeStep === index ? { ...selectedStepStyle, ...selectedStepLabelStyle } : selectedStepLabelStyle)
                           }}
                        >
                           {i18n.t(label)}
                        </StepLabel>
                     </Step>
                  ))}
               </Stepper>
               <Typography
                  sx={{
                     fontFamily: "Work Sans",
                     fontSize: 17,
                     fontWeight: 600,
                     lineHeight: "33px",
                     letterSpacing: "0em",
                     textAlign: "left",
                     marginLeft: "10px",
                     marginTop: "20px",
                  }}
               >
                  {i18n.t(["Dados académicos", "Dados biográficos", "Dados adicionais"][activeStep])}
               </Typography>
               <br />
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     {activeStep === 0 && studentData && <AnotherCustomTable data={dataForTables} />}
                     {activeStep === 1 && <AnotherCustomTable data={dataForTablea} />}
                     {activeStep === 2 && <AnotherCustomTable data={dataForTable} />}
                  </Grid>
               </Grid>
            </Grid>
         </Grid>

      </>
   );
}