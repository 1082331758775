import { useDraggable, DragOverlay } from "@dnd-kit/core";
import { useRef, useState } from "react";

const DraggableSubject = ({ subject, index, isDraggable }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { attributes, listeners, setNodeRef, isDragging } =
    useDraggable({
      id: String(index),
      disabled: !isDraggable, // Use the isDraggable prop to control the draggable state
    });

  const overlayRef = useRef();

  const style = {
    borderRadius: isDragging ? "10px" : undefined,
    border: isDragging ? "1px solid #ffc107" : undefined,
    backgroundColor: isDragging
      ? "#ffc107"
      : isHovering && isDraggable
      ? "#f0f0f0" // Change #f0f0f0 to your desired hover background color
      : undefined,
    color: isDragging ? "white" : undefined,
    // Ensure cursor changes to "pointer" only when isHovering and isDraggable are true
    cursor: isDraggable && isHovering ? "pointer" : "default",
  };

  const overlayStyle = {
    cursor: isDragging ? "pointer" : "default",
    borderRadius: "10px",
    backgroundColor: "#ffc107",
    color: "white",
    border: "1px solid #ffc107",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      {isDragging && (
        <DragOverlay>
          <div ref={overlayRef} style={overlayStyle}>
            <div>{subject.name}</div>
          </div>
        </DragOverlay>
      )}
      <div
        ref={setNodeRef}
        style={style}
        {...listeners}
        {...attributes}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className="subject"
      >
        {subject.name}
      </div>
    </>
  );
};

export default DraggableSubject;
