import { Step, StepButton, Stepper, Typography } from '@mui/material';
import './styles/history-analyse.css';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';

const steps = ["LIQUIDADO", "PAGO", "RUP GERADO", "GERAR RUP"];


const solveStatus = (val) => {

  switch (val) {
    case "AWAITING_PAYMENT": return "Gerar RUPE";
    case "RUPE_GENERATED": return "RUPE Gerado";
    case "PAYMENT_REJECTED": return "Pagamento Rejeitado";
    case "PAID": return "Pago";
    case "LIQUIDATED": return "Liquidado";
    case "UNDER_ANALYSIS": return "Em Analises";
    case "WAITING_FOR_STUDENT_INFO": return "Aguardar info-aluno";
    case "ENROLLED": return "Matriculado";
    case "APPROVED": return "Aprovado";
    case "REJECTED": return "Rejeitado";

    default: return " Indeterminado";
  }
}

const HistoryAnalyse = ({values}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();
  const { id } = useParams();
  
  const [status,setStatus] = useState([])
  
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${apiUrl}/student-document-issuance/${id}/history`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });
        const data = response.data
        
        // Agora, você pode acessar os dados, como nome e ano da matrícula, a partir de 'data'
       
        setStatus(data) // Suponha que o ano esteja em 'year' 
        // Faça o que for necessário com esses dados
        // Atualize o estado ou exiba as informações na página
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [id]);
  const stepStyles = {
    "& .Mui-active": {
      color: "#FFC107",
      fontSize: "250px",
      
    },
    "$ .MuiStepConnector-line": {
      borderColor: "pri",
      borderWidth: "3px",
    }

  }

  return (
    <div className='history-analyis'>

      <Typography sx={{ paddingBottom: "9px", color: "#003B76" }} variant='h3'>Histórico</Typography>

      <Stepper orientation='vertical' sx={stepStyles} activeStep={status?.length - 1 || 0}>
        {status?.map((label, index) => {
          const d = new Date(label.createdAt)
          return(
          <Step key={label}>
            <StepButton sx={{ color: '#C2D6E5' }} icon={<CheckBoxIcon sx={{ fontSize: 34 }} />} >
              <div className='step'>
                  <p className='title'>{solveStatus(JSON.parse(label.data)?.status) }</p>
                  <p className='date'>{d.getDate()}/{d.getMonth()}/{d.getFullYear()} - {d.getHours()}:{d.getMinutes()}</p>
              </div>
            </StepButton>
          </Step>
        )})}
      </Stepper>


    </div>
  )
}

export default HistoryAnalyse