import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from 'prop-types'; 
const theme = createTheme({
  palette: {
    warning: {
      main: "#FFC107",
    },
  },
});

const GenericButton = (props) => {
  const { handler } = props;

  const config = {
    disabled: props.disabled,
    variant: props.variant,
    onClick: handler,
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        {...config}
        to={props.url}
        type={props.type}
        size="small"
        color="warning"
        sx={{
          color: "white",
          padding: "15px 20px",
          height: "5px",
          boxShadow: "none", // Remove shadow
          ...props?.sx,
        }}
      >
        {props.label.toUpperCase()}
      </Button>
    </ThemeProvider>
  );
};
GenericButton.propTypes = {
  handler: PropTypes.func.isRequired, // handler como função obrigatória
  disabled: PropTypes.bool, // disabled como booleano opcional
  variant: PropTypes.string, // variant como string opcional
  url: PropTypes.string, // url como string opcional
  type: PropTypes.string, // type como string opcional
  label: PropTypes.string.isRequired, // label como string obrigatória
  sx: PropTypes.object, // sx como objeto opcional
};

export default GenericButton;
