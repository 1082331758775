import { Typography, Grid, Button } from "@mui/material";
import UploadButton from "../../../components/form-components/upload-button";
import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

const IssueDocumentAnalyse = () => {

  const redirectToDocumentIssuances = () => {
    // Redirecione para a página "/student-document-issuances"
    window.location.href = "/issuedocumentSE";
  };
  const { keycloak } = useKeycloak();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState({});
  const [entityId, setEntityId] = useState(null);
  const { id } = useParams();
  const [toast, setToast] = useState({ message: "", severity: "success" })
  const handleClick = (m, s) => {
    setToast({ message: m, severity: s })
    setTimeout(() =>
      setOpen(true), 200);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const [nome, setNome] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [isSenderLoading, setIsSenderLoading] = useState(false);
  const [status, setStatus] = useState("")
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userRole, setUserRole] = useState("");

  const uploadFiles = async (files, id, name, type) => {
    setIsSenderLoading(true);
  
    try {
      const formData = new FormData();
      formData.append('entityID', id);
      formData.append(name, files[name]);
  
      const response = await axios.post(`${apiUrl}/student-document-issuance-attachment/${type}`, formData, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
        'Content-Type': 'multipart/form-data',
      });
      if (type === "rup") {
        redirectToDocumentIssuances(); // Função para redirecionar a página
      } else {
        window.location.href = "/issuedocumentSE";
      }
     
      console.log('Resposta da Requisição de Upload:', response);
    } catch (error) {
      console.error(`Erro ao fazer upload do arquivo ${name}:`, error);
  
      if (error.response && error.response.status === 400) {
        console.error('Detalhes da resposta de erro:', error.response.data.message);
      }
  
      handleClick(`Erro ao fazer upload do arquivo ${name}: ${error.message}`, "error");
    } finally {
      setIsSenderLoading(false);
    }
  };
  useEffect(() => {
    // Fetch user role from keycloak or another source
    const fetchUserRole = async () => {
      try {
        // Fetch user role logic here, update setUserRole with the fetched role
        // For example, if roles are present in keycloak.token, you can extract it
        const roles = keycloak.tokenParsed?.realm_access?.roles || [];
        setUserRole(roles.includes("secretaria") ? "secretaria" : "");
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, [keycloak]);


  const solveStatus = (val) => {
    switch (val) {
      case "AWAITING_PAYMENT": return { rupe: false, prof: true, note: true };
      case "RUPE_GENERATED": return { rupe: true, prof: false, note: true };
      case "PAYMENT_REJECTED": return { rupe: true, prof: true, note: true };
      case "PAID": return { rupe: true, prof: true, note: false };
      case "LIQUIDATED": return { rupe: true, prof: true, note: true };
      case "ISSUED": return { rupe: true, prof: true, note: true };
      default: return { rupe: true, prof: true, note: true };
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${apiUrl}/student-document-issuance/${id}`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });
        const data = response.data;


        setDocumentType(data.documentId?.name)
        setNome(data.student.name)
        setEntityId(data.id)
        setStatus(data.status)
        setDocumentNumber(data.student.mechanographicNumber)
        // Faça o que for necessário com esses dados
        // Atualize o estado ou exiba as informações na página
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [id]);
  const NextButton = styled(Button)({
    backgroundColor: '#FFC107',
    color: 'white',
    width: "100%",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FFCC39',
    },
  });

  return (
    <>

      <Grid style={{ backgroundColor: "white", padding: "40px", paddingTop: "55px" }}>
        <Typography sx={{ paddingBottom: "30px", color: "#003B76" }} variant='h3'>Pagamento</Typography>
        <Typography sx={{ paddingTop: "0px", paddingBottom: "30px", color: "#003B76" }} variant='h4'>Dados do Aluno</Typography>
        <div className='flex-wrap s-18 pb-15'>
          <div className='w-240'>
            <p className='ref'>Nome:</p>
          </div>
          <div className='w-480'>
            <p className='value'>{nome}</p>
          </div>
        </div>

        <div className='flex-wrap s-18 pb-15'>
          <div className='w-240'>
            <p className='ref'>Identificação:</p>
          </div>
          <div className='w-480'>
            <p className='value'>{documentNumber}</p>
          </div>
        </div>

        <div className='flex-wrap s-18 pb-69'>
          <div className='w-240'>
            <p className='ref'>Tipo de Documento:</p>
          </div>
          <div className='w-480'>
            <p className='value'>{documentType}</p>
          </div>
        </div>

        <Typography sx={{ paddingTop: "0px", paddingBottom: "30px", color: "#003B76" }} variant='h4'>Anexos:</Typography>


        <div className='flex-wrap s-18 pb-22'>
          <div className='w-240'>
            <p className='ref'>RUPE:</p>
          </div>
          <div className='w-480'>
            <UploadButton files={files} id={entityId} set={setFiles} name="RupDoc" disabled={solveStatus(status)?.rupe} apiEndpoint={`${process.env.REACT_APP_API_URL}/student-document-issuance-attachment`} entityParamFirst={false} />
          </div>
        </div>
        <div className='flex-wrap s-18 pb-22'>
          <div className='w-240'>
            <p className='ref'>Comprovante de pagamento:</p>
          </div>
          <div className='w-480'>
            <UploadButton files={files} id={entityId} name="ConfirmationPayment" set={setFiles} disabled={solveStatus(status)?.prof} apiEndpoint={`${process.env.REACT_APP_API_URL}/student-document-issuance-attachment`} entityParamFirst={false} />
          </div>
        </div>
        <div className='flex-wrap s-18 pb-69'>
          <div className='w-240'>
            <p className='ref'>Nota de Liquidação:</p>
          </div>
          <div className='w-480'>
            <UploadButton files={files} id={entityId} name="Liquidation" set={setFiles} disabled={solveStatus(status)?.note} apiEndpoint={`${process.env.REACT_APP_API_URL}/student-document-issuance-attachment`} entityParamFirst={false} />
          </div>
        </div>
        <Grid container spacing={5}>

          <Grid style={{ padding: 0, alignItems: "end" }} item xs={2}>

          </Grid>
        </Grid>
        <Grid container spacing={5} justifyContent="flex-end">
          <Grid item xs={2}>
            <div className='rup-button' style={(solveStatus(status)?.rupe && solveStatus(status)?.prof && solveStatus(status)?.note) ? { display: 'none' } : {}} >
              <NextButton variant="contained" onClick={() => {
                if (!solveStatus(status).rupe)
                  uploadFiles(files, id, "RupDoc", "rup")
                if (!solveStatus(status).prof)
                  uploadFiles(files, id, "ConfirmationPayment", "payment-confirmation")
                if (!solveStatus(status).note)
                  uploadFiles(files, id, "Liquidation", "accept-payment")
              }
              } >{isSenderLoading ? "Carregando" : !solveStatus(status).rune ? "Enviar Aluno" : !solveStatus(status).prof ? "Enviar Aluno" : !solveStatus(status).note ? "Enviar Aluno" : ""}</NextButton>
            </div>
          </Grid>
        </Grid>

      </Grid>

   
      <Grid>

      {status === "LIQUIDATED" && (
      <Grid style={{ marginTop: "10px" }}>
        {/* Add styling (margin, padding, etc.) to create separation */}
        <Grid style={{ backgroundColor: "white", padding: "40px", paddingTop: "55px" }}>
          <Typography>Anexar documento</Typography>
          <Grid container spacing={1} display={"flex"}>
            <Grid item xs={4}>
              <UploadButton files={files} id={entityId} name="Document" set={setFiles} apiEndpoint={`${process.env.REACT_APP_API_URL}/student-document-issuance-attachment`} entityParamFirst={false}/>
            </Grid>
            <Grid item xs={1}>
              <NextButton variant="contained" onClick={() => {
                uploadFiles(files, id, "Document", "Document");
              }}>Enviar</NextButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )}
      </Grid>


    </>
  )
}

export default IssueDocumentAnalyse;