/**
 * Usage
 * 
 * filteredData is the array used for searching
 * mainData is the array fetched from API, here used as a fallback when search result is empty
 * slicedItemsis the array to be redered in the UI, returned by the hook from either filteredData or mainData
 * 
 * //Pagination  & Items per page filter
  const {
    currentPage,
    totalPages,
    slicedItems,
    setCurrentPage,
    pageSize,
    handlePageSizeChange,
  } = usePagination(filteredData.length > 0 ? filteredData : mainData);
 */

import { useState } from "react";

const usePagination = (items, availablePageSizes = [5, 10, 20, 50, 100]) => {
  if (!Array.isArray(items)) {
    throw new Error("usePagination: 'items' should be an array.");
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);

  //To be discarded

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const slicedItems = items.slice(startIndex, endIndex);
  //
  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value);
    if (!availablePageSizes.includes(newPageSize)) {
      throw new Error(
        "Selected page size is not included in available page sizes."
      );
    }
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const handlePaginationData = (response) => {
    setTotalPages(response?.data?.meta?.totalPages);
    setItemsPerPage(response?.data?.meta?.itemsPerPage);
    setTotalItems(response?.data?.meta?.totalItems);
    setCurrentPage(response?.data?.meta?.currentPage);
  };

  return {
    totalItems,
    itemsPerPage,
    currentPage,
    totalPages,
    slicedItems,
    setCurrentPage,
    pageSize,
    handlePageSizeChange,
    availablePageSizes,
    handlePaginationData,
  };
};

export default usePagination;
