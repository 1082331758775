import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from 'prop-types';
const theme = createTheme({
  palette: {
    warning: {
      main: "#FFC107",
    },
  },
});

export const MainButton = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        type={props.type}
        disabled={props.disabled}
        variant="outlined"
        to={props.url}
        component={Link}
        size="medium"
        color="warning"
      >
        {props.name}
      </Button>
    </ThemeProvider>
  );
};
MainButton.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};