import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function AddNewDiscipline() {
    const { subjectId, studentId, subjectName } = useParams();
    const [evaluationData, setEvaluationData] = useState([]);
    const [finalGrade, setFinalGrade] = useState(null);
    const [averageGrade, setAverageGrade] = useState(null);
    const [result, setResult] = useState(null);

    useEffect(() => {
        const fetchEvaluationData = async () => {
            try {
                const response = await axios.get(`/evaluation/${subjectId}/${studentId}`);
                setEvaluationData(response.data);
            } catch (error) {
                console.error("Erro ao buscar avaliações:", error);
            }
        };

        const fetchFinalGrade = async () => {
            try {
                const response = await axios.get(`/evaluation/final-grade/${subjectId}/${studentId}`);
                const finalGradeValue = response.data.finalGrade.toFixed(2);
                setFinalGrade(finalGradeValue);
                setAverageGrade(response.data.AverageGrade.toFixed(2));
                setResult(response.data.result);
            } catch (error) {
                console.error("Erro ao buscar nota final:", error);
            }
        };

        fetchEvaluationData();
        fetchFinalGrade();
    }, [subjectId, studentId]);

    const getResultColor = () => {
        if (result === "Aprovado") {
            return "#4CAF50"; // Cor verde mais claro para aprovado
        } else  {
            return "#FF5722"; // Cor vermelha para reprovado
        } 
    };

    return (
        <Grid sx={{ backgroundColor: "white", padding: "80px", paddingTop: "30px" }}>
            <Typography variant="h4"
                sx={{
                    color: "#212529",
                    fontSize: "18px",
                    marginBottom: "40px",
                }}>{subjectName}</Typography>

            <Grid item xs={12}>
                <Typography variant="h4"
                    sx={{
                        color: "#212529",
                        fontSize: "20px",
                        marginBottom: "10px",
                    }}>Avaliações</Typography>
                <TableContainer>
                    <Table sx={{ minWidth: 650, border: '1px solid #E9ECF5', borderRadius: '5px' }} size="small" aria-label="a dense table">

                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#F8FAFD', height: '56px', color: '#939a9f' }} >
                                <TableCell sx={{ color: '#939a9f' }} align="center">BI</TableCell>
                                <TableCell sx={{ color: '#939a9f' }} align="center">BII</TableCell>
                                <TableCell sx={{ color: '#939a9f' }} align="center">TI</TableCell>
                                <TableCell sx={{ color: '#939a9f' }} align="center">TII</TableCell>
                                <TableCell sx={{ color: '#939a9f' }} align="center">PI</TableCell>
                                <TableCell sx={{ color: '#939a9f' }} align="center">PII</TableCell>
                                <TableCell sx={{ color: '#939a9f' }} align="center">Avaliação Contínua</TableCell>
                                <TableCell sx={{ color: '#939a9f' }} align="center">EXA</TableCell>
                                <TableCell sx={{ color: '#939a9f' }} align="center">Nota final</TableCell>
                                <TableCell sx={{ color: '#939a9f' }} align="center">Resultado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">{evaluationData.find(e => e.evaluationType === 'B I')?.value?.toFixed(2) ?? "-"}</TableCell>
                                <TableCell align="center">{evaluationData.find(e => e.evaluationType === 'B II')?.value?.toFixed(2) ?? "-"}</TableCell>
                                <TableCell align="center">{evaluationData.find(e => e.evaluationType === 'T I')?.value?.toFixed(2) ?? "-"}</TableCell>
                                <TableCell align="center">{evaluationData.find(e => e.evaluationType === 'T II')?.value?.toFixed(2) ?? "-"}</TableCell>
                                <TableCell align="center">{evaluationData.find(e => e.evaluationType === 'P I')?.value?.toFixed(2) ?? "-"}</TableCell>
                                <TableCell align="center">{evaluationData.find(e => e.evaluationType === 'P II')?.value?.toFixed(2) ?? "-"}</TableCell>
                                <TableCell align="center">{averageGrade ?? "-"}</TableCell>
                                <TableCell align="center">{evaluationData.find(e => e.evaluationType === 'EXA')?.value?.toFixed(2) ?? "-"}</TableCell>
                                <TableCell align="center">{finalGrade ?? "-"}</TableCell>
                                <TableCell align="center" style={{ color: getResultColor(), borderRight: '1px solid #E9ECF5' }}>{result ?? "-"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
