import React from 'react';
import { Table, TableBody, TableRow, TableCell, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';

const CustomTable = ({ label, buttonLabel, icon, buttonIcon, handleOpen, disabled,onClick }) => {
  return (
    <Table size="auto" aria-label="a dense table">
      <TableBody
        height="56px"
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 3px 2px rgba(0, 0, 0, 0.2);", // Sombra padrão
        }}
      >
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell
            component="th"
            scope="row"
            sx={{
              boxShadow: "0px 8px 16px 0px #ABBED133", // Adicionando a sombra desejada
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                {icon && React.cloneElement(icon, { style: { marginRight: '8px', color: "red" } })}
                <span style={{ color: "#003B76", fontWeight: "bold" }}>{label}</span>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    height: "27px",
                    color: "#FFC107",
                    backgroundColor: "#EFEFEF",
                    borderColor: "#FFC107",
                  }}
                  onClick={onClick || handleOpen } 
                  disabled={disabled}  
                >
                  <span style={{ fontSize: "12px" }}>{buttonLabel}</span>
                  {buttonIcon && React.cloneElement(buttonIcon, { style: { marginRight: '8px', color: "#FFC107" } })}
                </Button>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
CustomTable.propTypes = {
  label: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  buttonIcon: PropTypes.string.isRequired,
  handleOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CustomTable;
