const messages = {
  pt: {
    translations: {
      titles: {
        home: "Página Inicial",
        mainTitle: "Sistema de Gestão Académica",
      },
      messages: {

        createMessage: {
          default: "Recurso criado com sucesso",
          subject: "Disciplina criada com sucesso!",
          le: "Sala criada com sucesso!",
          classroom: "Tipo de Sala criada com sucesso!",
          department: "Departamento criado com sucesso!",
          graduations: "Graduação criada com sucesso!",
          cargo: "Cargo criado com sucesso!",
          category: "Categoria criada com sucesso!",
          contract: "Contrato criado com sucesso!",
          
        },
        updateMessage: {
          default: "O Recurso foi atualizado com sucesso",
          subject: "Disciplina atualizada com sucesso",
          le: "Sala atualizada com sucesso",
          classroom: "Tipo de Sala atualizado com sucesso",
          department: "Departamento atualizado com sucesso",
          graduations: "Graduação atualizada com sucesso",
          cargo: "Cargo atualizado com sucesso",
          category: "Categoria atualizada com sucesso",
          contract: "Contrato atualizado com sucesso",
          curricularplan: "Curso atualizado com sucesso!", // Corrigido
        },
        success: "Recurso eliminado com sucesso",
        createMessage: "Recurso criado com sucesso!",
        updateMessage: "O Recurso foi actualizado com sucesso",

        "createMessage": "Funcionário criado com sucesso!",

        updateMessage: "Funcionário atualizado com sucesso!",

       


        home: "Bem-vindo ao nosso Sistema de Gestão Acadêmica",
        wrong: "Algo está errado!",
        whatToDo: "O que gostaria de fazer?",
        confirmDelete: "Tem certeza que deseja eliminar este recurso?",
        error: "Erro ao eliminar o recurso",
        createTitle: "Estado da Criação",
        updateTitle: "Estado da Actualização",
        required: "Campo Obrigatório",
        beNumber: "Tem que ser um número",
        bePositiveNumber: "Tem que ser um número positivo",
        beEmail: "Email inválido",
        notFound: "Nenhum recurso encontrado",
        emissão:
          "Gostaríamos de informar que com apenas alguns cliques, você pode emitir seus documentos acadêmicos de forma rápida e conveniente diretamente em nossa plataforma de gestão acadêmica.",
        emissãos:
          "Lembramos que todos os documentos emitidos pela plataforma são válidos e possuem caráter oficial.",
      },
      others: {
        name: "Nome",
        quantity: "Quantidade",
        cost: "Custo",
        adquiredDate: "Data de Aquisição",
        description: "Descrição",
        dragToAdd: "Arraste para adicionar",
        disciplines: "Disciplinas",
        addDisciplines: "Adicionar Disciplinas",
        semester: "Semestre",
        year: "Ano",
        yearNumber: "Nº de anos",
        enrollId: "ID da Matricula",
        idCard: "Nº de Agente",
        student: "Alunos",
        course: "Curso",
        class: "Turma",
        createdAt: "Data de Criação",
        status: "Estado",
        canceledReasons: "Rasões do Cancelamento",
        visualize: "Visualizar",
        edit: "Editar",
        delete: "Eliminar",
        strand: "Vertente",
        personalInfo: "Informações Pessoal",
        firstName: "Primeiro Nome",
        lastName: "Último Nome",
        birthdate: "Data de Nascimento",
        contact: "Contacto",
        phone: "Telefone",
        email: "Email",
        address: "Endereço",
        createCourse: "Criar Curso",
        graduation: "Graduação",
        options: "Ações",
        NewG: "Nova Graduação",
        coordinator: "Coordenador",
        lativeYear: "Ano Letivo",
        graduationType: "Tipo de Graduação",
        addClassrooms: "Adicionar Salas de Aula",
        bribe: "Propina",
        NumberOfStudents: "Número de Estudantes",
        workload: "Carga Horária",
        payment: "Pagamento",
        amount: "Quantia",
        capacity: "Capacidade",
        numberOfStudents: "Número de Estudantes",
        teacher: "Professor",
        Classroom: "Sala de Aula",
        subject: "Disciplina",
        category: "Categoria",
        cod: "COD",
        dateOfAcquision: "Data de Aquisição",
        department: "Departamento",
        price: "Preço",
        designation: "Designação",
        startTime: "Hora de Início",
        endTime: "Hora de Fim",
        date: "Data",
        grade: "Nota",
        period: "Período",
        type: "Tipo",
        lesson: "Sala",
        evaluation: "Avaliação",
        startDate: "Data de Inicio",
        endDate: "Data de Término",
        headOfProject: "Líder do Projeto",
        numOfCollaborators: "Num. de Colaboradores",
        search: "Procurar",
        employee: "Funcionário",
        observations: "Observações",
        part: "Parcela",
        enroll: "Matricula",
        payee: "Beneficiário",
        executor: "Executor",
        paidDate: "Data de Pagamento",
        hiredDate: "Data de Contratação",
        role: "Cargo",
        contractType: "Tipo de Contrato",
        contract: "Contrato",
        salary: "Salário",
        bank: "Banco",
        userName: "Nome de Usario",
        password: "Codigo",
        cancel: "Cancelar",
        fullName: "Nome Completo",
        paymentMethod: "Formas de Pagamento",
        payerDetails: "Detalhes do Pagador",
        payeeDetails: "Detalhes do Beneficiario",
        schoolYear: "Ano letivo",
        schedules: "Horários",
        NumberOfVacancies: "Número de vagas",
        numberOfCandidates: "Número de candidatos",
        ProcessNumber: "Numero de Processo",
        StudentName: "Nome do aluno",
        Sex: "Sexo",
        Results: "Resultados",
        TypeofRoom: "Tipo de Sala",
        cap: "Capacidade",
        newClassroom: "Nova Sala",
        NextE: "Proximos Eventos",
        groups: "Grupos",
        courses: "Cursos",
        nºStudents: "Nº de Estudantes(auto)",
        nºGroups: "Nº de Grupos",
        NewDP: "Novo Departamento",
        Sigla: " Sigla",
        DocumentsAttached: "Documentos Anexados",
        emailInst: "Email Institucional",
        emailPers: "Email Pessoal",
        Nif: "Nif",
        StartFunctions: "Início das funções",
        EndFunctions: "Fim das funções",
        MainData: "Dados da Conta Principal",
        AccountNumber: "Número de Conta",
        SecondaryData: "Dados da Conta Secundária",
        listOfGraduation: "Lista de Graduação",
        UpdateDP: "Atualizar Departamento",
        filter: "Filtro",
        filterBy: "Filtrar por",
        noFilters: "Sem Filtros",
        generateGroups: "Criar Grupos",
        cardId: "ID Cartao",
        mechanographicNº: "Nº Mecanográfico",
        yearEnrolled: "Ano Matriculado",
        subjectsInArrears: "Disciplinas em Atraso",
        newrh: "Novo funcionario adicionado",
        NewS: "Nova Disciplina",
        curricularPlan: "Curso",
        Subjects: "Disciplina",
        createGroups: "Criar Grupos",
        tuition: "Propina",
        EXRecAppealExam: "EX REC = Exame de Recurso",
        MAcContinuousAssessmentAverage: "MAC = Média Avaliação Continua",
        NfFinalGrade: "NF = Nota Final",
        finalResult: "Nota Final",
        continuousEvaluation: "Avaliação Continua",
        createMessage: "Criado com Sucesso",
        finish: "Finalizar",
        disciplina: "Disciplina",
        NewYS: "Novo Ano Letivo",
        titlesub: "Lista de Disciplinas",
      },
      button: {
        register: "Registar",
        return: "Retornar Para A",
        stay: "Continuar Em",
        enroll: "Matricular",
        create: "Criar",
        add: "Adicionar",
        createClass: "Criar uma nova Turma",
        createEmployee: "Novo Funcionário",
        createTeacher: "Novo Professor",
        createCourse: "Criar um Curso",
        createSubject: "Criar Disciplinar",
        createLesson: "Criar Aula",
        resgisterEvaluation: "Registar Avaliação",
        previous: "Anterior",
        next: "Proximo",
        save: "Salvar",
        exams: "Novo",
        cancel: "Cancelar",
        subjects: "Disciplinas",
        schedules: "Horarios",
        students: "Estudantes",
        teachers: "Professores",
        addDisciplines: "Adicionar Disciplinas",
        addShedules: "Adicionar Horarios",
        studentSchedules: "Horário Aluno",
        teacherSchedules: "Horário Professor",
        filter: "Filtrar",
        new: "Novo",
        update: "Atualizar",
        addC: "Adicionar curso",
        addT: "Adicionar Professor",
      },
      sidebar: {
        Enroll: "Matriculas",
        Student: "Estudantes",
        Class: "Turmas",
        schedules: "Horários",
        Teacher: "Professores",
        Course: "Cursos",
        CouseCategory: "Categorias dos Cursos",
        Subject: "Disciplinas",
        Lesson: "Salas",
        Evaluation: "Avaliações",
        Projects: "Projetos",
        Asset: "Recursos",
        Classroom: "Salas de Aula",
        StudentFee: "Propinas",
        Department: "Departamento",
        Employees: "Funcionários",
        Payroll: "Folhas de pagamento",
        Bank: "Bancos",
        Leave: "Ano Letivo",
        Users: "Utilizadores",
        Exams: "Candidatos",
        Academic: "Academico",
        HumanResource: "Recursos Humanos",
        graduation: "Graduação",
        settings: "Configurações",
        RH: "RH",
        CurricularPlan: "Plano Curricular",
        Subjects: "Disciplinas",
        Note: "Notas",
        MySubjects: "Disciplinas",
        AcademicProfile: "Perfil Academico",
        EmissionDocument:"Emissão de Documentos",
        Report: "Relatórios",

      },
      sideBarTitle: {
        academic: "Acedemico",
      },
      curricularPlan: {
        title: "Lista de Cursos",
        titleCreate: "Novo Curso",
      },
      contract: {
        title: "Lista de Contratos",
        titleCreate: "Novo Contrato",
      },
      category: {
        title: "Lista de Categorias",
        titleCreate: "Nova Categoria",
      },
      role: {
        title: "Lista de Cargos",
        titleCreate: "Novo Cargo",
      },
      classroomType: {
        title: "Lista de Tipos de Sala",
        titleCreate: "Novo Tipo de Sala",
      },
      subjects: {
        title: "Lista de Disciplinas",
        titleCreate: "Nova Disciplina",
      },
      asset: {
        title: "Lista de Recursos",
        titleCreate: "Registar Recursos",
      },
      enroll: {
        title: "Lista de Matriculas",
        titleCreate: "Registar Matriculas",
        createMessage: "Estudante Matriculado",
      },
      class: {
        title: "Lista de Turmas",
        titleCreate: "Criar uma nova Turma",
        addClassToCourse: "Associar Turma a um Curso",
        subjectPlaning: "Planeamento das Aulas",
        viewClass: "Ver Turma",
        newClass: "Nova Turma",
      },
      student: {
        title: "Lista de Estudantes",
        registrationId: "ID Matricula",
      },
      teacher: {
        title: "Lista de Professores",
        cardId: "ID Cartão",
        name: "Nome",
        telephone: "Telefone",
        email: "Email",
        theoreticalDisciplines: "Disciplinas Teoricas",
        practicalDisciplines: "Disciplinas Práticas",
        availability: "Disponibilidade",
        ListOfDisciplines: "Lista das Disciplinas",
        emptyDisciplines: "Nenhuma disciplina listada!",
        schedulesGrid: "Grade de Horarios",
      },
      course: {
        title: "Lista de Cursos",
        empty: "Nenhum curso cadastrado!",
        titleCreate: "Curso",
        bribe: "propina",
        workingHours: "Carga Horaria",
        firstSemester: "1º Semestre",
        secondSemester: "2º Semestre",
        firstYear: "1º Ano",
        secondYear: "2º Ano",
        thirdYear: "3º Ano",
        fourthYear: "4º Ano",
        fifthYear: "5º Ano",
        sixthYear: "6º Ano",
        biology: "Biologia",
        physiotherapy: "Fisioterapia",
        biochemistry: "Bioquímica",
        anatomy: "Anatomia",
        semiology: "Semiologia",
        scheduleList: "Lista de Horarios",
        classList: "Lista de Turmas",
        name: "Nome",
        actions: "Ações",
        nºStudents: "Nº de Estudantes",
        new: "Novo",
        monday: "Segunda",
        tuesday: "Terca",
        wednesday: "Quarta",
        thursday: "Quinta",
        friday: "Sexta",
        saturday: "Sabado",
        class: "Turma",
        strand: "Vertente",
        period: "Período",
      },
      courseCategory: {
        title: "Lista das Categorias dos Cursos",
        titleCreate: "Criar Categorias de Cursos",
      },
     
      lesson: {
        title: "Lista de Aulas",
        titleCreate: "Criar Aula",
        lessonDetails: "Detalhes da Aula",
        lessonDate: "Data da Aula",
      },
      evaluation: {
        title: "Lista de Avaliações",
        titleCreate: "Criar Avaliação",
      },
      project: {
        title: "Lista de Projetos",
        titleCreate: "Criar um Projeto",
        studentCard: "Insira o ID do Cartão do Estudente",
        employeeCard: "Insira o ID do Cartão do Funcionario",
      },
      classroom: {
        title: "Lista de Salas de Aula",
        titleCreate: "Registar uma Sala de Aula",
        listClass: "Nova Sala de Aula",
        Update: "Sala de Aula Atualizada",
      },
      studentFee: {
        title: "Lista de Propinas",
        titleCreate: "Registar uma Propina",
        identification: "Identificação",
        documentRequest:"Solicitação de Documento",
        documentType:"Tipo de Documento",
        paymentReference:"Referência de Pagamento",
        documentProcessing:"O seu documento está em processamento!",
        paymentReferenceEmail:"Receberá um e-mail com a referencia de pagamento para finalizar a sua solicitação."
      },
      department: {
        title: "Lista de Departamentos",
        titleCreate: "Criar um Departamento",
        addDepart: "Adicionar Departamentos",
        addedDeparts: "Departamentos Adicionados",
        dpUpdate: "Departamento atualizado",
        dpCreate: "Departamento Criado com sucesso",
      },
      payroll: {
        title: "Lista de Folhas de Pagamento",
        titleCreate: "Registar um Pagamento",
        employeePayment: "Pagamento",
      },
      employee: {
        title: "Lista de Funcionarios",
        titleCreate: "Registar um Funcionario",
        hiringDetails: "Informações do Cargo",
        paymentDetails: "Informações de Pagamento",
        dpUpdate: "Dados de funcionario atualizados",
        formError:
          "Por favor, selecione um departamento, categoria de funcionário e cargo de funcionário",
          mc:"Estado de Criação",
      },
      bank: {
        title: "Lista de Bancos",
        titleCreate: "Registar Bancos",
      },
      leave: {
        title: "Lista de Licenças",
        titleCreate: "Registar uma Licença",
        leavePeriod: "Periodo de Licença",
        createMessage: "Licença Criada com Sucesso",
      },
      user: {
        title: "Lista de Utilisadores",
      },
      ExamsList: {
        title: "Lista de Candidatos",
        titleCreate: "Novo",
        editExms: "Editar Candidato",
      },
      graduations: {
        dpUpdate: "graduação atualizada",
        newGrad: "Nova Graduação",
      },
      subject: {
        subUpadate: "Disciplina Atualizada",
          titleCreate: "Criar disciplina",
          createMessage: "Disciplina Criada",
          updateMessage: "Disciplina Atualizada",
        
      },
      request: {
        netError:
          "Ocorreu um erro de rede. Por favor, verifique sua conexão e tente novamente.",
        serverError:
          "O servidor encontrou um erro. Por favor, tente novamente mais tarde.",
        unauthorized: "Você não tem permissão para acessar este recurso.",
        syntaxError:
          "Ocorreu um erro de sintaxe. Por favor, verifique os dados e tente novamente. Se o erro persistir, entre em contato com o administrador do sistema.",
        badRequest:
          "Ocorreu um erro na requisição. Por favor, verifique os dados e tente novamente. Se o erro persistir, entre em contato com o administrador do sistema.",
      },
      schedules: {
        createSuccessMessage: "Horario criado com sucesso",
        urlStay: "Horarios",
        urlGoBack: "Horario",
        noSchedule: "Para criar horarios tem que adicionar os dados corretamente",
        missingTeacher: "Por favor click no nome da disciplina para adicionar o professor",
      }
    },
  },
};

export { messages };
