import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import profile from "../../assets/profile.svg";
import PropTypes from 'prop-types';
export default function ProfileImage({ id, setFiles, files, errors, setFormData, values, disabled, edit, onChange }) {
   const { keycloak } = useKeycloak();
   const [profilePic, setProfilePic] = useState(null);
   const [imageSource, setImageSource] = useState(null); // New state variable
   const [uploadedImage, setUploadedImage] = useState(null);

   const getFile = async (id, name, set) => {
      if (id) {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/enrollAttachment/find/${name}/${id}`, {
               headers: {
                  Authorization: "Bearer " + keycloak.token,
               },
            });
            console.log("Profile PIC:", response);
            set(response.data?.data ? response.data.data : response.data);
         } catch (error) {
            console.error(error);
         }
      }
   };

   useEffect(() => {
      getFile(id, "ProfilePic", setProfilePic);
   }, [keycloak.token, id]);

   useEffect(() => {
      if (uploadedImage) {
         setImageSource(uploadedImage);
      } else if (profilePic) {
         setImageSource(`${process.env.REACT_APP_API_URL}/enrollAttachment/download/${profilePic.id}`);
      } else {
         setImageSource(profile);
      }
   }, [uploadedImage, profilePic]);

   const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
         setFiles((o) => {
            o.ProfilePic = file;
            return o;
         });

         const reader = new FileReader();

         reader.onload = (e) => {
            setUploadedImage(e.target.result);
         };

         reader.readAsDataURL(file);
      }

      if (onChange) {
         setTimeout(() => {
            onChange();
         }, 500);
      }
   };

   const getLogoWidth = () => {
      const defaultWidth = 200;
      if (uploadedImage) {
         const img = new Image();
         img.src = uploadedImage;
         return img.naturalWidth || defaultWidth;
      }
      if (profilePic) {
         return profilePic.width || defaultWidth;
      }
      return defaultWidth;
   };

   return (
      <>
         <label htmlFor="profilePicInput" className="profile-pic">
            <img width={getLogoWidth()} src={imageSource} alt="Profile Pic" />
         </label>
         <input
            onClick={(e) => (disabled ? e.preventDefault() : null)}
            type="file"
            id="profilePicInput"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
         />
      </>
   );
}
ProfileImage.propTypes = {
   id: PropTypes.string, // id como string
   setFiles: PropTypes.func.isRequired, // setFiles como função obrigatória
   files: PropTypes.object.isRequired, // files como objeto obrigatório
   errors: PropTypes.object, // errors como objeto opcional
   setFormData: PropTypes.func, // setFormData como função opcional
   values: PropTypes.object, // values como objeto opcional
   disabled: PropTypes.bool, // disabled como booleano opcional
   edit: PropTypes.bool, // edit como booleano opcional
   onChange: PropTypes.func, // onChange como função opcional
 };