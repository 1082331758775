import { useState, useEffect } from "react";

/*
matchFunction

Returns a boolean

Receives 3 arguments: an item, the value to compare with item, and the property in item (if object or index of array, etc.)

Example:

const matchFunction = (item, compareValue, property) => {
    const lowercaseItem = item[property].toLowerCase();
    const lowercaseValue = compareValue.toLowerCase();
    return lowercaseItem.includes(lowercaseValue);
  };
*/

/**
 * For handleFilter
 *
 * filterProperties must be an array of objects with property name and value (which may be multiple separated by comma)
 *
 *
 */

export const useSearch = (data, matchFunction, properties) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (Array.isArray(data)) {
      if (searchTerm) {
        const searchResults = data.filter((currentItem) =>
          matchFunction(currentItem, searchTerm, properties)
        );
        setFilteredData(searchResults);
      } else {
        setFilteredData(data);
      }
    }
  }, [searchTerm, data, properties]);

  return { searchTerm, setSearchTerm, filteredData };
};

export const useFilter = (data) => {
  const [filteredData, setFilteredData] = useState(data);
  const [canFilter, setCanFilter] = useState(false);

  return { filteredData, setCanFilter, canFilter };
};
