import axios from "axios";

const setAuthorizationHeader = (keycloak) => ({
  Authorization: "Bearer " + keycloak.token,
});

const buildQueryString = (filters, filterProp = "") =>
  Object.entries(filters)
    .flatMap(([key, values]) =>
      Array.isArray(values)
        ? values.map(
            (value) =>
              `${filterProp ? filterProp : ""}${encodeURIComponent(
                key
              )}=${encodeURIComponent(value)}`
          )
        : `${filterProp ? filterProp : ""}${encodeURIComponent(
            key
          )}=${encodeURIComponent(values)}`
    )
    .join("&");

const removeCachedData = (
  uniqueDataLocalStorageName,
  currentPage,
  pageSize,
  queryString,
  totalPages
) => {
  for (let i = 1; i <= totalPages; i++) {
    localStorage.removeItem(
      `${uniqueDataLocalStorageName}${i}Size${pageSize}${queryString}`
    );
  }
};

export const getItem =
  (
    apiUrl,
    keycloak,
    incomingSetter,
    loadingSetter,
    currentPage,
    uniqueDataLocalStorageName,
    pageSize,
    handlePaginationData,
    setCurrentFilters,
    disabledPagination,
    setQueryString,
    disabledSearching
  ) =>
  async (filters = {}, filterProp = "") => {
    try {
      if (typeof loadingSetter === "function") {
        loadingSetter(true);
      }
      if (typeof setCurrentFilters === "function") {
        setCurrentFilters(filters);
      }

      let response;

      if (uniqueDataLocalStorageName === undefined) {
        response = await axios.get(apiUrl, {
          headers: setAuthorizationHeader(keycloak),
        });
      } else {
        const queryString = buildQueryString(filters, filterProp);
        if (typeof setQueryString === "function") {
          setQueryString(queryString);
        }

        const cachedData = localStorage.getItem(
          `${uniqueDataLocalStorageName}${currentPage}Size${pageSize}${queryString}`
        );

        if (cachedData) {
          response = JSON.parse(cachedData);
        } else {
          const url = disabledPagination
            ? disabledSearching
              ? apiUrl
              : `${apiUrl}?${queryString}`
            : `${apiUrl}?page=${currentPage}&limit=${pageSize}&${queryString}`;

          response = await axios.get(url, {
            headers: setAuthorizationHeader(keycloak),
          });

          localStorage.setItem(
            `${uniqueDataLocalStorageName}${currentPage}Size${pageSize}${queryString}`,
            JSON.stringify(response)
          );
        }
        handlePaginationData(response);
      }

      incomingSetter(response?.data?.data || response?.data);

      if (typeof loadingSetter === "function") {
        loadingSetter(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

export const newItemPage = (navigate, pathUrl) => () => {
  navigate(pathUrl);
};

export const visualizeItem = (pathUrl, navigate,type) => (id) => {
  if (type) {
    const url = `${pathUrl}/${id}?type=${type}&disabled=${true}`;
    navigate(url);
  } else {
  const url = `${pathUrl}/${id}?disabled=${true}`;
  navigate(url);
  }
};

export const editItem = (pathUrl, navigate, type) => (id) => {
  if (type) {
    const url = `${pathUrl}/${id}?type=${type}`;
    navigate(url);
  } else {
    const url = `${pathUrl}/${id}`;
    navigate(url);
  }
  
};

export const deleteItem =
  (
    pathUrl,
    keycloak,
    getFunction,
    setLoading,
    handleSuccess,
    handleBadRequest,
    errorFallback,
    totalPages,
    pageSize,
    uniqueDataLocalStorageName,
    currentFilters,
    currentPage, // Adicionei o parâmetro currentPage aqui
    filterProp = ""
  ) =>
  (id) => {
    setLoading(true);

    axios
      .delete(`${pathUrl}/${id}`, {
        headers: setAuthorizationHeader(keycloak),
      })
      .then((response) => {
        console.log("response", response);
        if (response && response?.status === 200) {
          handleSuccess();
          if (totalPages >= 1) {
            const queryString = currentFilters && buildQueryString(currentFilters, filterProp);
            removeCachedData(
              uniqueDataLocalStorageName,
              currentPage,
              pageSize,
              queryString,
              totalPages
            );
          }
          getFunction(currentFilters);
        } else {
          console.error(`Falha ao excluir: `, response);
          handleBadRequest(response);
        }
      })
      .catch(function (error) {
        console.error("Erro:", error);
        errorFallback(error);
      });
  };
