import {  Grid,Typography,  Stepper, Step, StepLabel,  } from "@mui/material";
import { i18n } from "../../../translate/i18n";
import { useState,useEffect } from "react";
import EastIcon from '@mui/icons-material/East';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ModalConfirmation from "../issueDocumentComponent/ModalConfirmation";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import Loading from "../../../components/loading/loading";


const solveStatus = (val) => {
    switch (val) {
        case "AWAITING_PAYMENT":
            return "Gerar RUPE";
        case "RUPE_GENERATED":
            return "RUPE Gerado";
        case "PAYMENT_REJECTED":
            return "Pagamento Rejeitado";
        case "PAID":
            return "Pago";
        case "LIQUIDATED":
            return "Liquidado";

        case "ISSUED":
            return "Enviado";
        default:
            return "Indeterminado";
    }
};
export default function IssueDocumentStudentListingPage() {
    const [activeStep, setActiveStep] = useState(0);
    const [documentNames, setDocumentNames] = useState([]);
    const [open, setOpen] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(1);
    const { keycloak } = useKeycloak();
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [studentDatas, setStudentDatas] = useState(null);
    const [issuedDocuments, setIssuedDocuments] = useState([]);

    const fetchStudentByUserAccountId = () => {
        const userId = keycloak.idTokenParsed.sub;
        return axios.get(`${apiUrl}/students/user-account/${userId}`, {
            headers: {
                Authorization: "Bearer " + keycloak.token,
            },
        })
            .then(response => response.data)
            .catch(error => {
                console.error(error);
                return null;
            });
    };

    useEffect(() => {
        if (keycloak.token) {
            getDoc();
        }
        if (keycloak.authenticated && keycloak.idTokenParsed) {
            setUserId(keycloak.idTokenParsed.sub);
            console.log("ID do Usuário:", keycloak.idTokenParsed.sub);

            // Buscar informações do estudante
            fetchStudentByUserAccountId()
                .then(studentData => {
                    // Faça algo com os dados do estudante, por exemplo, exiba no console
                    console.log("Dados brutos do estudante:", studentData);

                    if (studentData) {
                        setStudentDatas(studentData);
                        console.log("Informações do Estudantes:", studentDatas);
                    } else {
                        console.error("Dados do estudante não disponíveis.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao buscar informações do estudante:", error);
                });
        }
    }, [keycloak.token, page]);

    useEffect(() => {
        const fetchIssuedDocuments = () => {
            if (keycloak.authenticated && studentDatas && studentDatas.id) {
                axios.get(`${apiUrl}/student-document-issuance/student/${studentDatas.id}`, {
                    headers: {
                        Authorization: "Bearer " + keycloak.token,
                    },
                })
                    .then(response => {
                        setIssuedDocuments(response.data);
                        console.log("Documentos Emitidos para o Usuário:", response.data);
                    })
                    .catch(error => {
                        console.error("Erro ao buscar documentos emitidos:", error);
                    });
            }
        };

        fetchIssuedDocuments();
    }, [keycloak.authenticated, keycloak.token, studentDatas]);

    const getDoc = async () => {
        if (!page) {
            return;
        }

        const params = new URLSearchParams({ page: page, });

        try {
            const response = await axios.get(`${apiUrl}/document-student-can-request?` + params.toString(), {
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                },
            });
            setDocumentNames(response.data);
            if (localStorage.getItem("enployeeslPage"))

                setPage(response.data.page);

        } catch (error) {
            console.error(error);
        }
    };

    const customStepperStyle = {
        padding: "10px",
        margin: "0",
        marginLeft: "-30px",
    };


    const selectStep = (step) => {
        setActiveStep(step);
    };

    const CustomConnector = () => {
        return null;
    };

    const CustomStepIcon = () => {
        return null;
    };

    const customStepLabelStyle = {
        background: "#EFEFEF",
        fontWeight: "bold",
        padding: "0",
        margin: "0",
    };

    const selectedStepStyle = {
        background: "white",
    };

    const selectedStepLabelStyle = {
        color: "yellow",
    };

    const [selectedDocument, setSelectedDocument] = useState({
        id: null,
        name: "",
    });

    // ...

    const handleOpen = (documentName, documentId) => {
        setSelectedDocument({
            id: documentId,
            name: documentName,
        });
        setOpen(true); // Function to open the dialog
    };
    const handleClose = () => {
        setOpen(false); // Function to close the dialog
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            // Fetch the student information by userId
            const studentData = await fetchStudentByUserId(userId);

            if (studentData && selectedDocument.id) {
                // Use the selected document information
                const { id: documentId, name: documentName } = selectedDocument;

                // Ensure that studentId is available and is a valid number
                if (studentData.id && typeof studentData.id === 'number') {
                    console.log("Valid studentId:", studentData.id);

                    // Make the POST request to issue the document
                    await axios.post(`${apiUrl}/student-document-issuance`, {
                        documentId,
                        studentId: studentData.id,
                    }, {
                        headers: {
                            Authorization: "Bearer " + keycloak.token,
                        },
                    });
                    const urlParams = new URLSearchParams({ docType: documentName, name: studentData.name, mechanographicNumber: studentData.mechanographicNumber });

                    // Convert the URLSearchParams object to a string
                    const paramString = urlParams.toString();
                    window.location.href = "/issuedocument-confirmation?" + paramString
                    handleClose(); // Close the dialog after agreement
                } else {
                    // Handle the case where studentId is not valid
                    console.error("Invalid studentId:", studentData.id);
                }
            } else {
                // Log the available information for debugging purposes
                console.error("Student information or document ID not found");
                console.log("studentData:", studentData);
                console.log("selectedDocument:", selectedDocument);
            }
        } catch (error) {
            // Handle any errors that occur during the process
            console.error("Error processing document issuance:", error);
        } finally {
            setLoading(false);
        }
    };


    const fetchStudentByUserId = async (userId) => {
        try {
            const response = await axios.get(`${apiUrl}/students/user-account/${userId}`, {
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                },
            });

            // Assuming the response is the student data
            return response.data;
        } catch (error) {
            console.error("Error fetching student by userId:", error);
            return null;
        }
    };
    const handleDownload = async (documentId, documentName) => {
        console.log("Chamando handleDownload");
        console.log("doc", documentId);
        try {
            // Fetch the student information by userId
            const studentData = await fetchStudentByUserId(userId);
            console.log("testar");

            if (studentData) {
                // Use the student information to get the student ID

                // Fetch the document ID using the proccess endpoint
                const proccessResponse = await axios.get(`${apiUrl}/student-document-issuance-attachment/proccess/${documentId}`, {
                    headers: {
                        Authorization: "Bearer " + keycloak.token,
                    },
                });

                // Filter the documents to find the one with the desired name
                const desiredDocument = proccessResponse.data.find((doc) => doc.name === documentName);

                if (desiredDocument) {
                    const desiredDocumentId = desiredDocument.id;

                    // Fetch the document ID using the find endpoint
                    const findResponse = await axios.get(`${apiUrl}/student-document-issuance-attachment/find/${documentName}/${desiredDocumentId}`, {
                        headers: {
                            Authorization: "Bearer " + keycloak.token,
                        },
                    });

                    // Ensure the document is found in the findResponse if needed

                    // Fetch the document download URL using the specific document ID
                    const downloadResponse = await axios.get(`${apiUrl}/student-document-issuance-attachment/${desiredDocumentId}`, {
                        headers: {
                            Authorization: "Bearer " + keycloak.token,
                        },
                        responseType: 'blob',
                    });

                    // Create a blob from the response data
                    const blob = new Blob([downloadResponse.data], { type: 'application/pdf' });

                    // Create a download link
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${documentName}.pdf`; // Set the filename based on the original document name
                    document.body.appendChild(a);

                    // Trigger the download
                    a.click();

                    // Clean up
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                } else {
                    console.error("Document not found in the proccess data");
                }
            } else {
                // Log the available information for debugging purposes
                console.error("Student information not found");
                console.log("studentData:", studentData);
            }
        } catch (error) {
            console.error("Error handling document download:", error);
            // Handle any errors that occur during the download process
        }
    };

    return (
        <>
            <Grid container   >
                {loading && <Loading />}
                <Grid item xs={12}>
                    <br />
                    <br />
                    <Grid
                        style={{
                            width: "850px",
                            marginLeft: "-10px",
                            marginRight: "-100px",
                        }}
                    >
                        <Grid style={{ margin: "20px" }}>
                            <Typography
                                variant="h6"
                                style={{
                                    color: "#212529",
                                    fontFamily: "Work Sans",
                                    fontSize: 20,
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "28px",
                                    marginBottom: "8px",
                                }}
                            >Emitir Documentos</Typography>
                            <Typography style={{
                                color: "#F1C61B",
                                fontFamily: "Work Sans",
                                fontSize: 20,
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "28px",
                                marginBottom: "8px",
                            }}>
                                Pode emitir os documentos abaixo
                            </Typography>

                            <Typography
                                variant="p"
                                style={{
                                    color: "#212529",
                                    fontFamily: "Work Sans",
                                    fontSize: 14,
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "19px",
                                    marginBottom: "8px",
                                }}
                            >
                                {i18n.t("messages.emissão")}
                            </Typography>
                            <br />
                            <Typography
                                variant="p"
                                style={{
                                    color: "#212529",
                                    fontFamily: "Work Sans",
                                    fontSize: 14,
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "19px",
                                    marginBottom: "8px",
                                    marginTop: "20px",}}
                            >
                                {i18n.t("messages.emissãos")}
                            </Typography>

                        </Grid>
                    </Grid>
                    <Grid item xs={10} style={customStepperStyle}>
                        <Stepper
                            style={{ width: "600px", cursor: "pointer", paddingLeft: "0", peddingRight: "0" }}
                            activeStep={activeStep}
                            alternativeLabel
                            connector={<CustomConnector />}
                        >
                            <Step sx={{ paddingLeft: "0", paddingRight: "0" }}>
                                <StepLabel
                                    StepIconComponent={CustomStepIcon}
                                    onClick={() => selectStep(0)}


                                    style={

                                        activeStep === 0
                                            ? {
                                                ...customStepLabelStyle, ...selectedStepStyle,
                                                ...selectedStepLabelStyle
                                            }
                                            : { ...customStepLabelStyle, ...selectedStepLabelStyle }
                                    }
                                >
                                    {i18n.t("Solicitar emissão")}
                                </StepLabel>
                            </Step>
                            <Step sx={{ paddingLeft: "0", paddingRight: "0" }}>
                                <StepLabel
                                    StepIconComponent={CustomStepIcon}
                                    onClick={() => selectStep(1)}
                                    style={
                                        activeStep === 1
                                            ? { ...customStepLabelStyle, ...selectedStepStyle, ...selectedStepLabelStyle }
                                            : { ...customStepLabelStyle, ...selectedStepLabelStyle }
                                    }
                                >
                                    {i18n.t("Documentos Solicitados")}
                                </StepLabel>
                            </Step>
                            <Step sx={{ paddingLeft: "0", paddingRight: "0" }}>
                                <StepLabel
                                    StepIconComponent={CustomStepIcon}
                                    onClick={() => selectStep(2)}
                                    style={
                                        activeStep === 2
                                            ? { ...customStepLabelStyle, ...selectedStepStyle, ...selectedStepLabelStyle }
                                            : { ...customStepLabelStyle, ...selectedStepLabelStyle }
                                    }
                                >
                                    {i18n.t("Documentos Emitidos")}
                                </StepLabel>
                            </Step>
                        </Stepper>
                        <br />
                        {activeStep === 0 && (
                            <Grid container spacing={1}>
                                {documentNames.map((document) => (
                                    <Grid item xs={12} key={document.id}>
                                        <CustomTable
                                            label={document.name}
                                            icon={<PictureAsPdfIcon style={{ marginRight: '8px', color: "red" }} />}
                                            buttonLabel="Solicitar Emissão"
                                            buttonIcon={<EastIcon />}
                                            handleOpen={() => handleOpen(document.name, document.id)}

                                        />

                                    </Grid>
                                ))}

                            </Grid>
                        )}

                        {activeStep === 1 && (
                            <Grid container spacing={1}>
                                {issuedDocuments?.map((issuedDocument) => {
                                    console.log("issuedDocument", issuedDocument);
                                    return (
                                        <Grid item xs={12} key={issuedDocument.id}>
                                            <CustomTable
                                                label={issuedDocument?.documentId?.name}
                                                icon={<PictureAsPdfIcon style={{ marginRight: '8px', color: "red" }} />}
                                                buttonLabel={solveStatus(issuedDocument.status)}
                                                disabled={true}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )}

                        {activeStep === 2 && (
                            <Grid container spacing={1}>
                                {issuedDocuments
                                    ?.filter((issuedDocument) => issuedDocument.status === "ISSUED")
                                    ?.map((issuedDocument) => {
                                        console.log("Mapeando Documento Emitido:", issuedDocument);
                                        return (
                                            <Grid item xs={12} key={issuedDocument.id}>
                                                <CustomTable
                                                    label={issuedDocument.documentId?.name}
                                                    icon={<PictureAsPdfIcon style={{ marginRight: '8px', color: "red" }} />}
                                                    buttonLabel="Baixar Documento"
                                                    buttonIcon={<VerticalAlignBottomIcon />}
                                                    onClick={() => handleDownload(issuedDocument.id, issuedDocument.documentId?.name)}
                                                />
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        )}



                    </Grid>
                </Grid>
            </Grid>
            <ModalConfirmation
                open={open}
                head={i18n.t("Solicitar emissão")}
                body={i18n.t("Certificado de conclusão de curso?")}
                documentName={selectedDocument.name}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
            />

        </>
    )
}
