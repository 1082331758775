import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { Grid, Typography, IconButton, Button, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { i18n } from "../../../../translate/i18n";
import AutoSearch from "../../../../components/autoSearch/AutoSearch";

export default function DisciplinaModal({
  open,
  onClose,
  onConfirm,
  title = i18n.t("others.subject"),
  data = undefined,
  selected = [], 
  type
}) {
  const [disciplinas, setDisciplinas] = useState([]);
  const [selectedDisciplinas, setSelectedDisciplinas] = useState(selected);
  const [searchText, setSearchText] = useState("");
  const { keycloak } = useKeycloak();

  console.log("Initial selected: ", selectedDisciplinas);
  console.log("Initial passeed selected: ", selected);

  const apiUrl = process.env.REACT_APP_API_URL;
  const addMore = (lista) => {
    setDisciplinas(prev => [...prev, ...lista])
  }
  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (open && !Array.isArray(data)) {
      getList(type, setDisciplinas);
    }
    //If no selectedDisciplinas, initialize it with all data
    if (Array.isArray(data) && !selectedDisciplinas.length) {
      setDisciplinas(data);
      setSelectedDisciplinas(selected);
    }
  }, [open, data]);

  const handleDisciplinaSelect = (disciplina) => {
    const updatedDisciplinas = disciplinas.filter(
      (item) => item.id !== disciplina.id
    );
    setSelectedDisciplinas([...selectedDisciplinas, disciplina]);
    setDisciplinas(updatedDisciplinas);
  };

  const handleDisciplinaRemove = (disciplina) => {
    const updatedDisciplinas = selectedDisciplinas.filter(
      (item) => item.id !== disciplina.id
    );
    setSelectedDisciplinas(updatedDisciplinas);

    // Add the removed discipline back to the general list
    setDisciplinas([...disciplinas, disciplina]);
  };

  const DraggableDisciplina = ({ disciplina, index, onRemove }) => {
    const [, drop] = useState({
      accept: "DISCIPLINA",
      hover: (item) => {
        const draggedIndex = item.index;
        if (draggedIndex !== index) {
          const updatedDisciplinas = [...selectedDisciplinas];
          const draggedDisciplina = updatedDisciplinas[draggedIndex];

          updatedDisciplinas.splice(draggedIndex, 1);
          updatedDisciplinas.splice(index, 0, draggedDisciplina);

          setSelectedDisciplinas(updatedDisciplinas);
        }
      },
    });

    const [{ isDragging }] = useState({
      type: "DISCIPLINA",
      item: { index },
    });

    return (
      <div
        ref={(node) => drop(node)}
        style={{
          opacity: isDragging ? 0.5 : 1,
          display: "flex",
          alignItems: "center",
          background: "#E9ECF5",
          padding: "8px",
          border: "1px solid #E9ECF5",
          borderRadius: "16px",
          marginBottom: "8px",
          transition: "opacity 0.2s ease-in-out",
          "&:hover": {
            opacity: 0.8,
          },
        }}
      >
        <Typography style={{ marginRight: "auto" }}>
          {disciplina.name}
        </Typography>
        <CloseIcon onClick={() => onRemove(disciplina)} size="small" />
      </div>
    );
  };

  const filteredDisciplinas = disciplinas.filter((disciplina) =>
    disciplina.name.toLowerCase().includes(searchText.toLowerCase())
  );

  console.log("Filtered Disciplinas: ", filteredDisciplinas);

  const handleFinalizar = () => {
    onConfirm(selectedDisciplinas);
    onClose();
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <>
          <Box

            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-60%, -50%)",
              backgroundColor: "white",
              width: "30%",
              maxWidth: "400px",
              maxHeight: "80vh",

              border: "1px solid #000",
            }}
          >
            <Grid container
              spacing={3} sx={{
                maxWidth: "400px",
                maxHeight: "80vh",
                overflowY: "auto", padding: "20px", backgroundColor: "white", paddingBottom: 0,
              }}>
              <Grid container justifyContent="flex-end">
                <IconButton
                  onClick={onClose}
                  sx={{ position: "absolute", top: 0, right: 0, marginRight: 2, zIndex: 999 }}
                >
                  <img
                    src="/icon/close.svg"
                    alt="Close"
                    style={{ width: 35.96, height: 35.96 }}
                  />
                </IconButton>
              </Grid>

              <Grid item xs={12} marginBottom={2} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    color: "#003B76",
                    fontFamily: "Work Sans",
                    fontSize: "20px",
                    fontWeight: 700,
                    lineHeight: "23px",
                  }}
                >
                  {`${i18n.t("button.add")} ${title}`}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "-24px",
                }}
              >
                <AutoSearch
                  placeholder={i18n.t("others.search")}
                  setSearch={setSearchText}
                  search={searchText}
                  sx={{ width: "100%", bgcolor: "white" }}
                  inputSx={{ bgcolor: "white" }}
                  iconPosition="end"
                  onClear={() => setSearchText("")}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              >
                {selectedDisciplinas.map((disciplina, index) => (
                  <DraggableDisciplina
                    key={disciplina.id + disciplina.name}
                    disciplina={disciplina}
                    index={index}
                    onRemove={handleDisciplinaRemove}
                  />
                ))}
                {selectedDisciplinas.length > 0 && (
                  <hr style={{ width: "100%", borderBottom: "0.1px  #E9ECF5" }} />
                )}
              </Grid>

              <Grid item xs={12}>
                {filteredDisciplinas.map((disciplina, index) => (
                  <Typography
                    key={disciplina.name + disciplina.id}
                    sx={{ cursor: "pointer", marginBottom: 2 }}
                    onClick={() => handleDisciplinaSelect(disciplina)}
                  >
                    {disciplina.name}
                  </Typography>
                ))}



              </Grid>
            
              <Grid
                item
                xs={12}
                sx={{
                
                  textAlign: "left", 
                  marginTop: "8px",
                  marginBottom: "80px", 
                  marginLeft: "16px",   
                  color: "#003B76", 
                  cursor: "pointer", 
                  textDecoration: "underline", 
                  fontSize: "18px", 
                }}
                
              onClick={() => { 
                  getList(type + "?page=" + (Math.ceil(disciplinas.length / 20) + 1), addMore);
                }}
              >
                ver mais
              </Grid>


            </Grid>
            <Box

              sx={{
                marginTop: "auto",
                textAlign: "center",  
                position: "fixed",      
                bottom: 0, 
                width: "100%",
                background: "white", 
                py: "20px"
              }}
            >

              <Button onClick={handleFinalizar}>{i18n.t("others.finish")}</Button>
            </Box>

          </Box>

        </>
      </Modal>
    </>
  );
}
