import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import PropTypes from 'prop-types';
const DateTimePicker = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name);

  const configDateTimePicker = {
    ...field,
    ...otherProps,
    size: "small",
    type: "date",
    variant: "outlined",
    label: false,
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  };

  if (meta?.touched && meta?.error) {
    configDateTimePicker.error = true;
    configDateTimePicker.helperText = meta.error;
  }

  return (
    <div>
      <p style={{ fontSize: "13px", fontWeight: "600", color: "#42474B", margin: "0px" }}>{otherProps.label}</p>
      <TextField {...configDateTimePicker} />
    </div>
  );
};
DateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  // Define other props validation if needed
};

export default DateTimePicker;
