import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  TextField,
  Grid,
  Typography,
  Select,
  Button,
  MenuItem
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useParams } from "react-router-dom";
import CustomPagination from "../../components/pagination";

export default function RegisterEvaluation() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isTextFieldEnabled, setIsTextFieldEnabled] = useState(true);
  const [evaluation, setEvaluation] = useState([]);
  const [semester, setSemester] = useState([]);
  const [selectedEvaluationType, setSelectedEvaluationType] = useState("");
  const [selectedSemester, setSelectedSemester] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();
  const { courseId, subjectId, subjectName } = useParams();
  const [students, setStudents] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [employeeData, setEmployeeData] = useState([]);
  const [page, setPage] = useState(1);
  // Estado para armazenar temporariamente as notas dos alunos
  const [studentGrades, setStudentGrades] = useState({});
  
  const fetchSubjectEvaluationsByType = async (subjectId, type) => {
    try {
      const response = await axios.get(
        `${apiUrl}/evaluation/all-evaluation-of-one-subject-by-type/subject/${subjectId}/type/${type}`,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      // Atualiza o estado com os resultados recebidos
      const evaluations = response.data;
      const updatedGrades = {};
      evaluations.forEach((evaluation) => {
        // Use o ID do aluno como chave e a nota como valor
        updatedGrades[evaluation.studentId] = evaluation.value;
      });
      // Atualiza o estado studentGrades com as notas
      setStudentGrades(updatedGrades);
    } catch (error) {
      console.error("Error fetching subject evaluations by type:", error);
    }
  };
  
  
  const handleTextFieldChange = (event, studentId) => {
    const { value } = event.target;

    if (!isNaN(value) && value !== null) {
      // Atualiza o estado studentGrades com a nova nota do aluno
      setStudentGrades(prevState => ({
        ...prevState,
        [studentId]: value
      }));
      setIsSaveEnabled(true);
    } else {
      console.error("Invalid grade value.");
    }
  };

  const handleSalvarAlteracoesClick = async () => {
    setIsDisabled(false);
    setIsTextFieldEnabled(false);
    setIsSaveEnabled(false);

    // Filtra apenas os alunos que têm notas preenchidas
    const studentsWithGrade = students.filter((student) => studentGrades[student.id]);

    for (const student of studentsWithGrade) {
      if (selectedEvaluationType && selectedSemester) {
        await postEvaluation(
          selectedEvaluationType,
          studentGrades[student.id],
          student.id
        );
      } else {
        console.warn(`Evaluation not sent for ${student.name} because evaluation type and/or semester are not selected.`);
      }
    }
  };

  const handleAbrirEdicaoClick = () => {
    setIsDisabled(true);
    setIsTextFieldEnabled(true);
  };

  const handleSelectChange = (value) => {
    setSelectedEvaluationType(value);
    fetchSubjectEvaluationsByType(subjectId, value);
  };

  const handleSemesterChange = (value) => {
    setSelectedSemester(value);
  };

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmployeeInfo = async () => {
    try {
      if (keycloak.authenticated) {
        const userId = keycloak.idTokenParsed.sub;
        const response = await axios.get(
          `${apiUrl}/employees/user-account/${userId}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token,
            },
          }
        );
        setEmployeeData(response.data);
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const fetchStudents = async () => {
    if (!page) {
      return;
    }

    const params = new URLSearchParams({ page: page });
    try {
      const response = await axios.get(
        `${apiUrl}/courses/${courseId}/subjects/${subjectId}/students?` +
        params.toString(),
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      setStudents(response.data.data);
      setPage(response.data.page);
      setTotalPages(response.data.meta.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const postEvaluation = async (evaluationType, value, studentId) => {
    try {
      const cleanedEvaluationType = evaluationType.replace("_", " ");
      const response = await axios.post(
        `${apiUrl}/evaluation`,
        {
          evaluationType: cleanedEvaluationType,
          value: parseFloat(value),
          teacher: employeeData.id,
          student: studentId,
          subject: parseInt(subjectId),
        },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      console.log("Evaluation posted successfully:", response.data);
    } catch (error) {
      console.error("Error posting evaluation:", error);
    }
  };

  useEffect(() => {
    getList("evaluation-type", setEvaluation);
    getList("semester", setSemester);
    fetchStudents();
    getEmployeeInfo();
  }, [page]);

  return (
    <Grid sx={{ backgroundColor: "white", padding: "80px", paddingTop: "30px" }}>
      <Grid container>
        <Grid
          container
          display={"flex"}
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: "40px" }}
        >
          <Grid item>
            <Typography
              variant="h4"
              sx={{
                color: "#212529",
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: "10px",
              }}
            >
              {subjectName}
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              sx={{
                color: isDisabled ? "#6c757d" : "#FFC107",
                borderColor: isDisabled ? "#6c757d" : "#FFC107",
                marginRight: "5px",
              }}
              disabled={isDisabled}
              onClick={handleAbrirEdicaoClick}
            >
              Abrir Edição
            </Button>
            <Button
              sx={{
                backgroundColor: isSaveEnabled ? "#FFC107" : "#D1D1D1",
                color: "#FFFFFF",
              }}
              disabled={!isSaveEnabled}
              onClick={handleSalvarAlteracoesClick}
            >
              Salvar Alterações
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          display={"flex"}
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: "10px" }}
        >
          <Grid item>
            <Typography
              variant="h4"
              sx={{ color: "#212529", fontWeight: "bold", fontSize: "20px" }}
            >
              Lançar Notas
            </Typography>
          </Grid>
          <Grid item alignItems="center">
            <Select
              label="Select 1"
              value={selectedEvaluationType}
              onChange={(event) => handleSelectChange(event.target.value)}
              sx={{ width: '160px', height: '35px', marginRight: '10px' }}
            >
              <MenuItem value="" disabled>
                Selecione um tipo de avaliação
              </MenuItem>
              {evaluation.map((typeObj) => {
                const key = Object.keys(typeObj)[0];
                const value = typeObj[key];
                return (
                  <MenuItem key={key} value={key}>{value}</MenuItem>
                );
              })}
            </Select>

            <Select
              label="Select 2"
              value={selectedSemester}
              onChange={(event) => handleSemesterChange(event.target.value)}
              sx={{ width: '160px', height: '35px', marginRight: '10px' }}
            >
              <MenuItem value="" disabled>
                Selecione um semestre
              </MenuItem>
              {semester.map((semesterObj) => (
                <MenuItem key={semesterObj.id} value={semesterObj.id}>{semesterObj.name}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table style={{ background: "rgba(248, 250, 253, 1)" }}>
            <TableBody>
              {students.map((student) => (
                <TableRow key={student.id}>
                  <TableCell>{student.name}</TableCell>
                  <TableCell style={{ padding: "10px" }}>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <TextField
                          variant="outlined"
                          size="small"
                          style={{
                            backgroundColor: "white",
                            width: "150px",
                            height: "45px",
                          }}
                          value={studentGrades[student.id] || ''}
                          onChange={(event) => handleTextFieldChange(event, student.id)}
                          disabled={!isTextFieldEnabled}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid sx={{ display: "flex", justifyContent: "center", marginTop: 5 }}>
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </Grid>
    </Grid>
  );
}
