import { Checkbox, IconButton, List, ListItem, Paper, Popper } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDroppable } from "@dnd-kit/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import PropTypes from 'prop-types';

const TimeSlot = ({ 
  id, 
  schedule, 
  groups, 
  setSchedule,
  disciplines, 
  updateScheduleWithTeacher,
  selectedStrand,
  selectedPeriod,
  viewOnly
}) => {
    const [teachers, setTeachers] = useState([]);
    const { keycloak } = useKeycloak();
    const apiUrl= process.env.REACT_APP_API_URL;
    const [openPopper, setOpenPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classroomRef = useRef(null);
    const [selectedTeacherId, setSelectedTeacherId] = useState(null);
    const { setNodeRef } = useDroppable({ id });
    const timeSlot = schedule[id];
  
    const getItemName = (itemId, items) => {
      if(itemId){
        const item = items?.find((item) => (item?.subject?.id || item?.id) === itemId);
        return item ? item.name || item.subject.name : 'Unassigned';
      }
      
    };

    const getListTeacher = useCallback(async (type, set) => {
      try {
        const response = await axios.get(`${apiUrl}/${type}`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });
        set(response.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    });
  
    const removeItem = (itemType) => {
      setOpenPopper(false)
      setSchedule((schedule) => {
        const newSchedule = { ...schedule };
        if (itemType === 'group') {
          newSchedule[id].groupId = '';
        } else if (itemType === 'classroom') {
          newSchedule[id].classroomId = '';
        } else if (itemType === 'discipline') {
          newSchedule[id].disciplineId = '';
        }
        return newSchedule;
      });
      
    };
    

    const handleClassroomClick = (event) => {
      setAnchorEl(classroomRef.current);
      setOpenPopper(!openPopper);

      getListTeacher(`classes/teachers/by-coordinator?subjectId=${event}&variantId=${selectedStrand[0].id}&periodId=${selectedPeriod[0].id}`, setTeachers);
    };

    const handleTeacherSelection = (teacherId) => {
      setSelectedTeacherId(teacherId);
      updateScheduleWithTeacher(id, teacherId); // Update the main schedule state
    }

    useEffect(() => {
      setSchedule(schedule)
    }, [])
    

    return (
      <List ref={setNodeRef} style={{ minHeight: 70, border: "1px solid #ccc", borderCollapse: "collapse", padding: 8, backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display:"flex", flexDirection: "column", gap: "2px",  width: "100%" }} ref={classroomRef} >  
          {timeSlot && timeSlot?.groupId && (
            <div style={{ cursor: "pointer", backgroundColor: "#E9ECF5", width: "100%", borderRadius: "2px", padding: "2px 0px 2px 2px" }} className='item-container'>
              <span style={{ fontSize: "0.7rem" , fontWeight: "bold" }}>
                {getItemName(timeSlot.groupId, groups)}
              </span>
              <IconButton disabled={viewOnly} onClick={() => removeItem('group')} className="delete-button" size="small">
                <DeleteIcon fontSize="small" />
              </IconButton>
            
            </div>
          )}
          {timeSlot && timeSlot?.classroomId && (
            <div style={{ backgroundColor: "#E9ECF5", width: "100%", cursor: "pointer", borderRadius: "2px", padding: "2px 0px 2px 2px" }} className='item-container'>
              <span style={{ fontSize: "0.7rem", fontWeight: "bold" }}>
                { timeSlot.classroomId}
              </span>
              <IconButton disabled={viewOnly} onClick={() => removeItem('classroom')} className="delete-button" size="small">
                <DeleteIcon fontSize="6px" />
              </IconButton>
            </div>
          )}
          {timeSlot && timeSlot?.disciplineId && (
            <div 
            onClick={viewOnly ? null : () => handleClassroomClick(disciplines[0]?.subject.id)} 
            style={{ backgroundColor: "#E9ECF5", height: "100%", width: "100%", cursor: "pointer", borderRadius: "2px", padding: "2px 0px 2px 2px" }} className='item-container'>
              <span style={{ fontSize: "0.7rem", fontWeight: "bold", display: "block", width: "80%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "el"  }}>
                {getItemName(timeSlot.disciplineId, disciplines)}
              </span>
              <IconButton disabled={viewOnly} onClick={() => removeItem('discipline')} className="delete-button" size="small">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          )}
          {!viewOnly && (
            <Popper placement="right" modifiers={[
              {
                name: 'arrow',
                enabled: true,
              },
            ]} open={openPopper} anchorEl={anchorEl}>
              <Paper elevation={1}>
                <div>
                  {teachers.map((teacher) => (
                    <ListItem 
                    style={{ padding: "2px 5px"}} key={teacher.teacher.id} 
                    onClick={() => handleTeacherSelection(teacher.teacher.id)}>
                      <Checkbox
                        size="small" 
                        checked={selectedTeacherId === teacher.teacher.id}
                        onChange={() => {
                          handleTeacherSelection(teacher.teacher.id)
                          setOpenPopper(false)
                        }}
                      />
                      {teacher.teacher.name}
                    </ListItem>
                  ))}
                </div>
              </Paper>
            </Popper>
          )}
        </div>
  
        <style jsx>{`
          .item-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .delete-button {
            visibility: hidden;
           
          }
          .item-container:hover .delete-button {
            visibility: visible;
          }
        `}</style>
      </List>
    );
};
TimeSlot.propTypes = {
  id: PropTypes.number.isRequired,
  schedule: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  disciplines: PropTypes.array.isRequired,
  setSchedule: PropTypes.func.isRequired,
  updateScheduleWithTeacher: PropTypes.func.isRequired,
  selectedStrand: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool.isRequired
};


export default TimeSlot