import {
  Box,
  Grid,
  Paper,
  Table,
  styled,
  tableCellClasses,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { i18n } from "../../../translate/i18n";
import AutoSearch from "../../../components/autoSearch/AutoSearch";
import FilterList from "../../../components/filterList/FilterList";
import { useEffect, useState } from "react";
import ActionButtons from "../../../components/actions-buttom/ActionButtons";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import CustomPagination from "../../../components/pagination";
import { format } from "date-fns";
import Loading from "../../../components/loading/loading";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFD",
    color: "#939a9f",
  },
  [`&.${tableCellClasses.body}`]: {},
}));

const solveStatus = (val) => {
  switch (val) {
    case "AWAITING_PAYMENT":
      return "Gerar RUPE";
    case "RUPE_GENERATED":
      return "RUPE Gerado";
    case "PAYMENT_REJECTED":
      return "Pagamento Rejeitado";
    case "PAID":
      return "Pago";
    case "LIQUIDATED":
      return "Liquidado";
    case "ISSUED":
      return "Enviado";
    default:
      return "Indeterminado";
  }
};

const IssueDocumentASListingPage = () => {
  const [search, setSearch] = useState("");
  const { keycloak } = useKeycloak();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [totalPages, setTotalPages] = useState();
  const [pages, setPages] = useState(
    localStorage.getItem("issuedocumentPage") || 1
  );
  const [incomingDate, setIncomingDate] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const issueDocumenAStAnalyse = (id) => {
    navigate(`/issuedocumentAnalyse/${id}`);
  };

  const setPage = (pages) => {
    setPages(parseInt(pages));
    localStorage.setItem("issuedocumentPage", pages);
  };

  const formatDateString = (dateString) => {
    const formattedDate = format(new Date(dateString), "dd/MM/yyyy HH:mm:ss");
    return formattedDate;
  };

  const getIssueDocuments = async () => {
    setLoading(true);
    if (!pages) {
      return;
    }
    try {
      const response = await axios.get(`${apiUrl}/student-document-issuance?`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      setIncomingDate(response.data?.data);
      console.log(incomingDate);
      if (localStorage.getItem("issuedocumentPage"))
        setPage(
          localStorage.getItem("issuedocumentPage") || response.data.pages
        );
      setTotalPages(response.data.meta.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (keycloak.token) {
      getIssueDocuments();
    }

  }, [keycloak.token, pages]);

  return (
    
      <Grid
        container
        component={Paper}
        sx={{
          height: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.7)", // Set the initial background color
          position: "relative",
          alignItems: { xs: "left", md: "initial" },
        }}
      >
        {loading && <Loading />}

        <Grid container component={Paper}>
          <Grid item xs={12}>
            {loading ? // Render nothing while loading
            null : (
              <div className="container-da-tabela-responsiva">
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650, border: "1px solid #E9ECF5" }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          colSpan={6}
                          style={{ height: "59px" }}
                        >
                          <Typography
                            variant="h3"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {i18n.t("Emissão de Documentos")}
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Box sx={{ width: "200px" }}>
                                <AutoSearch
                                  placeholder={i18n.t("others.search")}
                                  setSearch={setSearch}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "120px",
                                  height: "40px",
                                  border: "1px solid #E9ECF5",
                                }}
                              >
                                <FilterList
                                  cardStyle={{
                                    position: "absolute",
                                    indez: "1",
                                    top: "140px",
                                    right: "323px",
                                  }}
                                />
                                {i18n.t("others.filter")}
                              </Box>
                            </Box>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        height="56px"
                        sx={{ backgroundColor: "#F8FAFD" }}
                      >
                        <StyledTableCell align="left">
                          {i18n.t("Nº Mecanografico")}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {i18n.t("others.name")}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {i18n.t("others.documents")}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {i18n.t("others.date")}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {i18n.t("others.status")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {i18n.t("others.options")}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {incomingDate
                        ?.filter((enroll) => {
                          return (
                            search.toLocaleLowerCase() === "" ||
                            (enroll &&
                              enroll.student &&
                              enroll.student.name &&
                              enroll.student.name
                                .toLocaleLowerCase()
                                .includes(search))
                          );
                        })
                        .map((enroll) => (
                          <TableRow
                            key={enroll.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {enroll.student?.mechanographicNumber}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {enroll.student?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {enroll.documentId?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {formatDateString(enroll.createdDate)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {solveStatus(enroll.status)}
                            </TableCell>
                            <TableCell align="left">
                              <ActionButtons
                                id={enroll.id}
                                handleVisualize={issueDocumenAStAnalyse}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CustomPagination
                    totalPages={totalPages}
                    setPage={setPage}
                    pages={localStorage.getItem("enrollPage")}
                  />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    
  );
};

export default IssueDocumentASListingPage;
