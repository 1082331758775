import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { i18n } from "../../translate/i18n";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import PropTypes from 'prop-types';
const theme = createTheme({
  palette: {
    warning: {
      main: "#FFC107",
    },
  },
});

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleCloseReturn = () => {
    setOpen(false);
    navigate(props.urlGoBack);
  };

  const handleCloseStay = () => {
    setOpen(false);
    navigate(props.urlStay);
  };
  const handleClickOpen = () => {
    if (props.isValid) setOpen(true);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Button
          disabled={props.disabled}
          variant="contained"
          type="submit"
          fullWidth={true}
          onClick={handleClickOpen}
          color="warning"
          sx={{
            color: "white",
            paddingY: "5px",
            paddingX: "15px",
            boxShadow: "none", // Remove shadow
            height: "25px",
            ...props.styles,
          }}
        >
          {props.button}
        </Button>
      </ThemeProvider>

      {/* <Button disabled={props.disabled} variant="contained" type="submit" fullWidth="true" onClick={handleClickOpen} color='warning'>
        {props.button}
      </Button> */}

      <Dialog open={open}>
        <DialogTitle>{props.head}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseReturn}
            disabled={props.isLoading}
            sx={{ paddingX: "5px" }}
          >
            {" "}
            {i18n.t("button.return")} {props.goBack}{" "}
          </Button>

          <Button
            onClick={() => {
              handleCloseStay();
              if (props.customStayHandler) {
                props.customStayHandler();
              }
            }}
            disabled={props.isLoading}
            sx={{ paddingX: "5px" }}
          >
            {i18n.t("button.stay")} {props.stay}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
AlertDialog.propTypes = {
  urlGoBack: PropTypes.string.isRequired,
  urlStay: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  button: PropTypes.string.isRequired,
  head: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  goBack: PropTypes.string.isRequired,
  stay: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customStayHandler: PropTypes.func,
  disabled: PropTypes.bool,
  styles: PropTypes.object,
};
