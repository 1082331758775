import { DndContext } from "@dnd-kit/core";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import DraggableStudent from "./DraggableStudent";

const GroupMembers = ({ students, id }) => {
  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <List disablePadding>
        {students.map((student) => (
          <DraggableStudent key={student?.id} student={student} groupId={id} />
        ))}
      </List>
    </Box>
  );
};

export default GroupMembers;
