import React, { useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";

import Collapse from "@mui/material/Collapse";

import linkButton from "../../assets/link_button.svg";

import {
  DeleteIcon,
  ActiveSemesterIcon,
  ActiveYearIcon,
} from "./CurricularPlanIcons";
import "./AddCurricularPlan.css";
import { useDroppable } from "@dnd-kit/core";
import { v4 as uuidv4 } from "uuid";
import {
  truncateText,
  extractSemesterName,
} from "../../utils/functions/utilFunctions";


// New component for table cell
const TableCellComponent = ({
  column,
  data,
  dataIndex,
  isEditing,
  handleInputChange,
  handleDelete,
  inputValues,
  selectedSemester,
  nameId,
  procedenteId,
  removeTruncateLength,
}) => {
  const year = selectedSemester.split("º")[0] + "º Ano";
  const semester = extractSemesterName(selectedSemester);

  const {
    setNodeRef: setNodeRefProcedente,
    isOver: isOverProcedente,
    isOverCurrent: isOverCurrentProcedente,
  } = useDroppable({
    id: procedenteId,
  });

  const {
    setNodeRef: setNodeRefName,
    isOver: isOverName,
    isOverCurrent: isOverCurrentName,
  } = useDroppable({
    id: nameId,
  });

  const droppableStyle =
    isOverCurrentProcedente || isOverCurrentName
      ? {
          backgroundColor: "#ffc107",
          color: "white",
          width: "100%",
          height: "100%",
          padding: "20px", // Increase padding
          cursor: "pointer",
        }
      : {
          width: "100%",
          height: "100%",
          padding: "0px", // Increase padding
        };

  if (column.showCheckBox && column.field) {
    const inputValue =   inputValues[dataIndex]?.[column.field] !== undefined
    ? inputValues[dataIndex][column.field]
    : data[column.field] ?? "";
  
        console.log("Pedro",inputValue );

        
    return (
      <TableCell align="center">
        <Checkbox
  checked={!!inputValue} // Converte o valor para booleano
  onChange={(e) => handleInputChange(e, column.field, data, dataIndex)}
        />
      </TableCell>
    );
  } else if (column.input && column.field) {
    const inputValue =
      isEditing &&
      inputValues[dataIndex] &&
      inputValues[dataIndex][column.field] !== undefined
        ? inputValues[dataIndex][column.field]
        : data[column.field] !== undefined
        ? data[column.field]
        : "";
    return (
      <TableCell width={"100px"} align="center">
        {isEditing ? (
          <TextField
            type="number"
            value={inputValue.toString()}
            onChange={(e) =>
              handleInputChange(e, column.field, data, dataIndex)
            }
            InputProps={{
              sx: {
                height: "30px",
                padding: "0px 0px",
              },
            }}
            sx={{ width: "70px" }}
          />
        ) : (
          <span className="p-4">{inputValue}</span>
        )}
      </TableCell>
    );
  } else if (column.select && column.field) {
    return (
      <TableCell align="center">
        <div ref={setNodeRefProcedente} style={droppableStyle}>
          {data[column.field] && (
            <>
              {isEditing &&
                (data[column.field] === " " ? (
                  <div>&nbsp;</div>
                ) : (
                  <DeleteIcon
                    styles="self-center deleteIcon"
                    handleClick={() =>
                      handleDelete(
                        `${year}-${semester}-${dataIndex}`,
                        "procedente"
                      )
                    }
                  />
                ))}
              <Tooltip title={data[column.field]}>
                <span className="ml-2">
                  {truncateText(
                    data[column.field],
                    removeTruncateLength ? 20 : 13
                  )}
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </TableCell>
    );
  } else {
    return (
      <TableCell align="center">
        <div ref={setNodeRefName} style={droppableStyle}>
          {data[column.field] && (
            <>
              {isEditing &&
                (data[column.field] === " " ? (
                  <div>&nbsp;</div>
                ) : (
                  <DeleteIcon
                    styles="self-center deleteIcon"
                    handleClick={() =>
                      handleDelete(`${year}-${semester}-${dataIndex}`, "name")
                    }
                  />
                ))}
              <Tooltip title={data[column.field]}>
                <span className="ml-2">
                  {truncateText(
                    data[column.field],
                    removeTruncateLength ? 20 : 13
                  )}
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </TableCell>
    );
  }
};

const CollapsibleTableVariant = ({
  title,
  secondTitles,
  label,
  tableData,
  columnsTitle,
  onIconClick,
  selectedSemester,
  setLastInputData,
  isEditing,
  handleDelete,
  saveSemesterInputData,
  year,
  yearIndex,
  openRow,
  setOpenRow,
  firstColumnAlign = "left",
  removeTruncateLength = false,
}) => {
  const [openI, setOpenI] = useState(true);
  const [canHide, setCanHide] = useState(true);

  const [inputValues, setInputValues] = useState([]);

  const handleInputChange = (e, field, data, dataIndex) => {
    const value = field === "creditos" ? e.target.value : e.target.checked;
console.log("verrr",value );

   // if (isEditing) {
      let updatedValues;
      setInputValues((prevInputValues) => {
        updatedValues = [...prevInputValues];
        // Ensure that updatedValues[dataIndex] is an object and initialize the its other property
        if (!updatedValues[dataIndex]) {
          const currentSemester = extractSemesterName(selectedSemester);
          updatedValues[dataIndex] = {};
          updatedValues[dataIndex][
            "id"
          ] = `${yearIndex}-${currentSemester}-${dataIndex}`;
          switch (field) {
            case "creditos":
              updatedValues[dataIndex]["dispenca"] = data["dispenca"] || false;
              break;
            case "dispenca":
              updatedValues[dataIndex]["creditos"] = data["creditos"] || 0;
              break;
          }
        }
        // Update the specific field for this subject
        updatedValues[dataIndex][field] = value;
        return updatedValues;
      });
      setLastInputData(updatedValues);
    }
 // };

  const handleRowToggle = (year, index) => {
    const rowId = `${year}-${index}`;
    setOpenRow((prevOpenSemester) =>
      prevOpenSemester === rowId ? null : rowId
    );
    setCanHide(true);
  };

  return (
    <Box>
      <Table
        sx={{
          border: "1px solid #E9ECF5",
          width: "100%",
          marginTop: "40px",
          marginRight: "5px",
        }}
      >
        <TableHead style={{ backgroundColor: "#E4EBF5" }}>
          <TableRow>
            <TableCell
              sx={{
                height: "56.04px",
                width: "715px",
                padding: "8px",
                fontWeight: 700,
              }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpenI((prev) => !prev);
                  setCanHide((prev) => !prev);
                }}
                sx={{ marginX: "4px" }}
              >
                {openI ? <ActiveYearIcon /> : <ActiveSemesterIcon />}
              </IconButton>
              {title}
            </TableCell>
          </TableRow>
        </TableHead>

        {secondTitles &&
          secondTitles.map((secondTitle, index) => {
            return (
              <React.Fragment key={index}>
                <TableHead
                  style={{
                    backgroundColor: "#F7F9FC",
                    border: "1px solid #E0E0E0",
                  }}
                >
                  <Collapse in={openI}>
                    <TableRow>
                      <TableCell colSpan={columnsTitle.length}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingLeft: "30px",
                          }}
                        >
                          <Box sx={{}}>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                saveSemesterInputData(
                                  inputValues,
                                  setInputValues
                                );
                                onIconClick(title + secondTitle);
                                handleRowToggle(year.year, index);
                              }}
                            >
                              {openRow === `${year.year}-${index}` ? (
                                <ActiveSemesterIcon styles="semesterIcon" />
                              ) : (
                                <ActiveYearIcon styles="semesterIcon" />
                              )}
                            </IconButton>
                            {label}
                          </Box>
                          <Box sx={{}}>
                            {parseInt(secondTitle) % 2 === 0
                              ? "2º Semestre"
                              : "1º Semestre"}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </Collapse>
                </TableHead>

                <Collapse in={openRow === `${year.year}-${index}` && canHide}>
                  <Table
                    size="small"
                    style={{
                      border: "1px solid #E0E0E0",
                      backgroundColor: "#fff",
                      marginLeft: "0px",
                    }}
                  >
                    <TableHead style={{ justifyContent: "space-between" }}>
                      <TableRow
                        style={{ border: "1px solid #E0E0E0", height: "50px" }}
                      >
                        {columnsTitle.map((column, index) => (
                          <TableCell
                            key={index}
                            style={{
                              textAlign:
                                index === 0 ? firstColumnAlign : "center",
                              marginRight: "18px",
                              padding:
                                index !== 0 && index !== 3
                                  ? "0 25px"
                                  : index === 3
                                  ? "0 28px 0 25px"
                                  : "0 25px 0",
                              fontWeight: 600,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell
                          style={{ textAlign: "center", fontWeight: 600 }}
                          padding="none"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData[secondTitle]?.map((data, dataIndex) => (
                        <TableRow
                          key={data.id + selectedSemester + dataIndex}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {columnsTitle.map((column, index) => {
                            const year =
                              selectedSemester.split("º")[0] + "º Ano";
                            const semester =
                              extractSemesterName(selectedSemester);

                            const procedenteId = `procedente*${year}-${semester}-${dataIndex}-${uuidv4()}`;
                            const nameId = `name*${year}-${semester}-${dataIndex}-${uuidv4()}`;

                            return (
                              <TableCellComponent
                                column={column}
                                data={data}
                                dataIndex={dataIndex}
                                isEditing={isEditing}
                                handleInputChange={handleInputChange}
                                handleDelete={handleDelete}
                                inputValues={inputValues}
                                procedenteId={procedenteId}
                                nameId={nameId}
                                key={
                                  column.field +
                                  data.id +
                                  index +
                                  selectedSemester
                                }
                                selectedSemester={selectedSemester}
                                removeTruncateLength={removeTruncateLength}
                              />
                            );
                          })}

                          <TableCell align="center">
                            <IconButton>
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={linkButton} alt="linkButton" />
                              </button>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Collapse>
              </React.Fragment>
            );
          })}
      </Table>
    </Box>
  );
};

export default CollapsibleTableVariant;
