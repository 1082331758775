import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import { IconButton } from '@mui/material';
import { yellow } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import iconVisualizar from '../../assets/iconVisualizar.svg';
import iconEDit from '../../assets/iconEDit.svg';
import iconDelet from '../../assets/iconDelet.svg';
import PropTypes from 'prop-types';
export default function ActionButtons({ id, ...props }) {
    /**
 * Renders a set of action buttons given an id and a set of handlers.
 *
 * @param {object} props - An object containing the handlers and the id.
 * @param {function} props.handleDelete - A function to handle the delete action.
 * @param {function} props.handleEdit - A function to handle the edit action.
 * @param {function} props.handleVisualize - A function to handle the visualize action.
 * @param {string} props.id - The id of the element to edit/delete/visualize.
 * @return {JSX.Element} The JSX element of the action buttons.
 */
    const solveRole = (role) => {
        return (props.roles || []).includes(role)
    }

    const { handleDelete, handleDeleteItem, handleEdit, handleVisualize, handleCancel, disabled } = props;

    return (

        <MenuList sx={{ display: `${disabled ? 'none' : 'flex'}`, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >

            {
                handleVisualize &&
                (<IconButton size="small" color="secondary" onClick={e => handleVisualize(id)} >
                      <img src={iconVisualizar} alt="Visualize" style={{ width: '18px', height: '18px', color: yellow[700] }} />
                </IconButton>)
            }

            {
                handleEdit &&
                (<IconButton size="small" color="secondary" onClick={e => handleEdit(id)} >
                    <img src={iconEDit} alt="Visualize" style={{ width: '17px', height: '17px', color: yellow[700] }} />
                </IconButton>)
            }

            {
                solveRole("admin") && handleDelete &&
                (<IconButton size="small" color="secondary" onClick={e => handleDelete(id)} >
                    <DeleteIcon sx={{ color: yellow[700] }} fontSize="small" />
                </IconButton>)
            }

            {
                handleDeleteItem &&
                (<IconButton size="small" color="secondary" onClick={e => handleDeleteItem(id)} >
                   <img src={iconDelet} alt="Visualize" style={{ width: '17px', height: '17px', color: yellow[700] }} />
                </IconButton>)
            }

            {
                handleCancel &&
                (<IconButton size="small" color="secondary" onClick={e => handleCancel(id)} >
                    <CancelIcon sx={{ color: yellow[700] }} fontSize="small" />
                </IconButton>)
            }

        </MenuList>

    );

}
ActionButtons.propTypes = {
    id: PropTypes.string.isRequired,
    handleDelete: PropTypes.func,
    handleDeleteItem: PropTypes.func,
    handleEdit: PropTypes.func,
    handleVisualize: PropTypes.func,
    handleCancel: PropTypes.func,
    disabled: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string) // If props.roles is an array of strings
    // You can add more PropTypes for other props if needed
};