import PropTypes from 'prop-types';
import React from 'react';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

export default function ContainedButtons(props) {
  return (
    <Stack>
      <Button
        variant={props.variant}
        style={props.style}
        to={props.url}
        component={Link}
        disabled={props.disabled}
      >
        {props.name}
      </Button>
      {props.children}
    </Stack>
  );
}

ContainedButtons.propTypes = {
  variant: PropTypes.string.isRequired,
  style: PropTypes.object,
  url: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  children: PropTypes.node
};
