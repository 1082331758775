import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment, useState } from "react";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import GroupMembers from "./GroupMembers";
import { i18n } from "../../../translate/i18n";
import Textfield from "../../../components/form-components/text-field";
import { useFetchClass } from "../hooks/useFetchClass";
import ClassGroup from "./Group";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { useMemo } from "react";

export const TypeOfDroppable = {
  GROUP: "group",
  ITEM: "item",
};

export default function GroupListingPreview({
  turma,
  handleClassNameChange,
  className,
  handleGroupNameChange,
}) {
  const CLOSED = useMemo(
    () => Array(turma.groups.length).fill(false),
    [turma.groups.length]
  );
  const [isOpen, setIsOpen] = useState(CLOSED);
  const [isMainRowOpen, setIsMainRowOpen] = useState(true);

  const { groups } = turma;
  const [stores, setStores] = useState(groups);

  console.log("Stores: ", stores);

  const onDragEnd = (event) => {
    const { active, over } = event;

    if (!over || typeof over?.id !== "string") {
      console.error("Invalid drop target:", over);
      return;
    }

    const [sourceGroupId, sourceStudentId] = active.id.split("*");
    const [destinationGroupId, destinationStudentId] = over.id.split("*");

    const sourceGroupIndex = stores.findIndex(
      (store) => store.id === parseInt(sourceGroupId)
    );
    const destinationGroupIndex = stores.findIndex(
      (store) => store.id === parseInt(destinationGroupId)
    );

    if (sourceGroupIndex === -1 || destinationGroupIndex === -1) {
      console.error(
        "Invalid group index:",
        sourceGroupIndex,
        destinationGroupIndex
      );
      return;
    }

    let sourceStudentIndex = stores[sourceGroupIndex]?.students?.findIndex(
      (student) => student.id === parseInt(sourceStudentId)
    );
    let destinationStudentIndex = stores[
      destinationGroupIndex
    ]?.students?.findIndex(
      (student) => student.id === parseInt(destinationStudentId)
    );

    if (sourceStudentIndex === -1 || destinationStudentIndex === -1) {
      console.error(
        "Invalid student index:",
        sourceStudentIndex,
        destinationStudentIndex
      );
      return;
    }

    console.log("Source index: ", sourceGroupIndex, sourceStudentIndex);
    console.log(
      "Destination index: ",
      destinationGroupIndex,
      destinationStudentIndex
    );
    console.log("Source Group index: ", stores[sourceGroupIndex]);
    console.log("Destination Group index: ", stores[destinationGroupIndex]);

    const newStores = [...stores];
    const [removed] = newStores[sourceGroupIndex].students.splice(
      sourceStudentIndex,
      1
    );

    if (sourceGroupId === destinationGroupId) {
      // If source and destination groups are the same, swap the source student and destination student
      if (sourceStudentIndex < destinationStudentIndex) {
        // If source student's index is less than destination student's index, decrement destination student's index by one
        destinationStudentIndex--;
      }
      const [destinationStudent] = newStores[sourceGroupIndex].students.splice(
        destinationStudentIndex,
        1,
        removed
      );
      newStores[sourceGroupIndex].students.splice(
        sourceStudentIndex,
        0,
        destinationStudent
      );
    } else {
      // If source and destination groups are different, add the student to the end of the destination group
      newStores[destinationGroupIndex].students.push(removed);
    }

    setStores(newStores);
  };

  return (
    <DndContext onDragEnd={onDragEnd} collisionDetection={closestCenter}>
      <Box>
        <Table
          sx={{
            border: "1px solid #E9ECF5",
            width: "100%",
            marginTop: "40px",
            marginRight: "5px",
          }}
          aria-label="simple table"
        >
          <TableHead style={{ backgroundColor: "#E4EBF5" }}>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                colSpan={5}
                sx={{ paddingLeft: 2 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 4,
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{ border: 1, padding: 0.2 }}
                    onClick={() => {
                      setIsMainRowOpen((prevState) => !prevState);
                      setIsOpen(CLOSED);
                    }}
                  >
                    {isMainRowOpen ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </IconButton>
                  <Box>
                    <Textfield
                      name="className"
                      value={className}
                      onChange={(ev) => {
                        handleClassNameChange(ev);
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none", // remove border
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      inputProps={{
                        style: { fontWeight: "bold" },
                        size: className.length,
                      }}
                    />
                  </Box>
                  <Typography>
                    {`( ${turma.numberOfStudents} ${i18n.t(
                      "others.student"
                    )} )`}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {turma.groups.map((group, index) => (
              <ClassGroup
                key={group.id}
                index={index}
                group={group}
                isMainRowOpen={isMainRowOpen}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                handleGroupNameChange={handleGroupNameChange}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </DndContext>
  );
}
