import { CircularProgress, Box, Grid } from "@mui/material";
import PropTypes from 'prop-types';
const FallBackLoader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // This will center the item vertically
        height: "100%", // This will make the Grid take up the full height of its parent container
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%", // This will make the Box take up the full height of its parent container
          width: "100%", // This will make the Box take up the full width of its parent container
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    </Grid>
  );
};
FallBackLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired, // Required boolean prop
};

export default FallBackLoader;
