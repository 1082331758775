import React from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import PropTypes from 'prop-types';
const ConfirmationModal = ({ open, handleClose, handleConfirm, newStatus }) => {
  // Objeto de tradução dos estados
  const statusTranslations = {
    in_progress: '"Em andamento"',
    completed: '"Concluído"',
    creating: '"Em criação"',
  };

  // Função para obter a tradução do estado
  const translateStatus = (status) => {
    return statusTranslations[status] || status; // Retorna a tradução ou o próprio status se não houver tradução
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        Atualizar o estado para {translateStatus(newStatus)}? {/* Exibir o estado traduzido */}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} style={{ backgroundColor: '#FFC107', color: '#FFFFFF' }}autoFocus>
        Atualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  newStatus: PropTypes.string.isRequired
};

export default ConfirmationModal;
