import axios from "axios";

import { errorInterceptors, responseInterceptors } from "./interceptors";

const Api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

Api.interceptors.response.use(
    (response) => responseInterceptors(response),
    (errors) => errorInterceptors(errors)
)

export { Api }
