import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import Select from "../../../components/form-components/select";
import { i18n } from "../../../translate/i18n";
import HandleUrl from "../../../utils/HandleUrl";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainedButtons from "../../../components/button/ContainedButtons";

export default function CreateGroups() {
  let isDisabled= HandleUrl();
  let  courseCategoryIncommingData= {};

  const [selectedClass, setSelectedClass] = useState("");

  const CancelClick = () => {
    const navigate = useNavigate();
    navigate("/schedules");
  };


  return (
    
      <Grid
        style={{
         
          marginLeft: "-70px",
          marginRight: "-90px",
        }}
      >
        <Formik>
          <Form>
            <Grid style={{ display: "flex", gap: "10px" }}>
              <Grid
                container
                style={{
                  width: "870px",
                  height: "719px",
                  marginTop: "-10px",
                  backgroundColor: "white",
                  display: "block",
                  paddingLeft: "30px",
                }}
              >
                <Grid item style={{ paddingTop: "30px" }}>
                  <Typography variant="h1">
                    {i18n.t("course.titleCreate")}
                  </Typography>
                </Grid>

                <Grid
                  style={{ display: "flex", gap: "30px", marginTop: "30px" }}
                >
                  <Grid item xs={3}>
                    <Select
                      name={i18n.t("course.class")}
                      options={courseCategoryIncommingData}
                      label={i18n.t("course.class")}
                      disabled={isDisabled}
                      value={selectedClass} // Defina um valor adequado (selectedClass) aqui
                      onChange={(e) => setSelectedClass(e.target.value)}
                    ></Select>
                  </Grid>
                  <Grid item xs={3} style={{}}>
                    <Select
                      name={i18n.t("course.strand")}
                      options={courseCategoryIncommingData}
                      label={i18n.t("course.strand")}
                      disabled={isDisabled}
                      value={selectedClass} // Defina um valor adequado (selectedClass) aqui
                      onChange={(e) => setSelectedClass(e.target.value)}
                    ></Select>
                  </Grid>
                  <Grid item xs={3} style={{}}>
                    <Select
                      name={i18n.t("course.period")}
                      options={courseCategoryIncommingData}
                      label={i18n.t("course.period")}
                      disabled={isDisabled}
                      value={selectedClass} // Defina um valor adequado (selectedClass) aqui
                      onChange={(e) => setSelectedClass(e.target.value)}
                    ></Select>
                  </Grid>
                </Grid>
               

                <Grid
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "flex-end",
                    marginTop: "60px",
                    marginRight: "30px",
                  }}
                >
                  <Box style={{ marginLeft: "10px" }}>
                    <ContainedButtons
                      name={i18n.t("button.cancel")}
                      style={{
                        color: "#FFC107",
                        backgroundColor: "white",
                        border: "1px solid #FFC107",
                      }}
                      disabled={isDisabled}
                      variant="outlined"
                     
                    />
                  </Box>
                  <Box>
                    <ContainedButtons
                      name={i18n.t("button.register")}
                      style={{
                        color: "white",
                        backgroundColor: "#FFC107",
                        border: "none",
                      }}
                      disabled={isDisabled}
                      variant="outlined"
                      onClick={CancelClick}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid
                style={{
                  width: "299px",
                  height: "719px",
                  radius: "1px",
                  marginTop: "-10px",
                  paddingLeft: "30px",
                  backgroundColor: "white",
                }}
              >
                <Grid display={{ marginTop: "50px" }}>
                  <Typography
                    style={{
                      font: "Work Sans",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "16.38px",
                    }}
                  >
                    {i18n.t("course.addClassrooms")}
                  </Typography>
                  <Typography
                    style={{
                      marginTop: "15px",
                      font: "Work Sans",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "12.74px",
                    }}
                  >
                    {i18n.t("course.dragToAdd")}
                  </Typography>

                  <Box
                    sx={{ display: "flex", gap: "10px", width: "200px" }}
                    id={"external-events"}
                  >
                    <Box
                      style={{
                        width: "95px",
                        height: "36px",
                        backgroundColor: "#E9ECF5",
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {i18n.t("A 202")}
                    </Box>

                    <Box
                      style={{
                        width: "95px",
                        height: "36px",
                        backgroundColor: "#E9ECF5",
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {i18n.t("B 102")}
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", gap: "10px", width: "200px" }}>
                    <Box
                      style={{
                        width: "95px",
                        height: "36px",
                        backgroundColor: "#E9ECF5",
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {i18n.t("A 102")}
                    </Box>

                    <Box
                      style={{
                        width: "95px",
                        height: "36px",
                        backgroundColor: "#E9ECF5",
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {i18n.t("B 102")}
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", gap: "10px", width: "200px" }}>
                    <Box
                      style={{
                        width: "95px",
                        height: "36px",
                        backgroundColor: "#E9ECF5",
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {i18n.t("A 152")}
                    </Box>

                    <Box
                      style={{
                        width: "95px",
                        height: "36px",
                        backgroundColor: "#E9ECF5",
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {i18n.t("A 202")}
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", gap: "10px", width: "200px" }}>
                    <Box
                      style={{
                        width: "95px",
                        height: "36px",
                        backgroundColor: "#E9ECF5",
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {i18n.t("A 102")}
                    </Box>

                    <Box
                      style={{
                        width: "95px",
                        height: "36px",
                        backgroundColor: "#E9ECF5",
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {i18n.t("B 102")}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    
  );
}
