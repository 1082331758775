import { useDraggable, useDroppable, DragOverlay } from "@dnd-kit/core";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const DraggableStudent = ({ student, groupId }) => {
  const {
    attributes: draggableAttributes,
    listeners,
    setNodeRef,
    isDragging,
  } = useDraggable({
    id: `${groupId}*${student?.id}`,
  });

  const {
    setNodeRef: setDroppableNodeRef,
    isOver,
    isDropAnimating,
  } = useDroppable({
    id: `${groupId}*${student?.id}`,
  });

  const style = {
    borderRadius: isDragging ? "10px" : undefined,
    border: isDragging ? "1px solid #ffc107" : undefined,
    backgroundColor: isDragging ? "#ffc107" : undefined,
    color: isDragging ? "white" : undefined,
  };

  const droppableStyle = {
    backgroundColor: isOver || isDropAnimating ? "#ffc107" : undefined,
  };

  return (
    <>
      {isDragging && (
        <DragOverlay>
          <div
            ref={setDroppableNodeRef}
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: isDragging ? "pointer" : "normal",
            }}
          >
            <ListItem
              disablePadding
              sx={{
                paddingLeft: 10,
              }}
              style={style}
              {...draggableAttributes}
              {...listeners}
              ref={setNodeRef}
            >
              <ListItemButton
                disableRipple
                sx={{
                  "&:hover": {
                    backgroundColor: isDragging ? "transparent" : undefined, // remove hover effect
                  },
                  "&.Mui-focusVisible": {
                    backgroundColor: "transparent", // remove focus effect
                  },
                  marginLeft: "58px",
                }}
              >
                <ListItemText primary={student?.name} />
                <ListItemIcon>
                  <DragIndicatorIcon
                    style={{
                      color: isDragging ? "white" : "#C3CAD9",
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </div>
        </DragOverlay>
      )}
      {!isDragging && (
        <div
          ref={setDroppableNodeRef}
          style={{
            ...droppableStyle,
            display: "flex",
            justifyContent: "flex-end",
            border: isDragging ? undefined : "1px solid #E0E0E0",
          }}
        >
          <ListItem
            disablePadding
            sx={{
              paddingLeft: 10,
            }}
            style={style}
            {...draggableAttributes}
            {...listeners}
            ref={setNodeRef}
          >
            <ListItemButton
              disableRipple
              sx={{
                "&:hover": {
                  backgroundColor: "transparent", // remove hover effect
                },
                "&.Mui-focusVisible": {
                  backgroundColor: "transparent", // remove focus effect
                },
                marginLeft: "58px",
              }}
            >
              <ListItemText primary={student?.name} />
              <ListItemIcon>
                <DragIndicatorIcon
                  style={{
                    color: isDragging ? "white" : "#C3CAD9",
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </div>
      )}
    </>
  );
};

export default DraggableStudent;
