import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import PropTypes from 'prop-types';
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#FFC107",
  padding: "5px 35px",
  width: "80%",
  border: "2px dashed #a4a8ac",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  position: "relative",
}));

const CloseIcon = styled("span")({
  position: "absolute",
  top: "50%",
  right: "15px",
  cursor: "pointer",
  fontSize: "1.2rem",
  transform: "translateY(-50%)",
  color: "#808080", // Cor cinza para o ícone "X"
});

const UploadButton = ({
  set,
  name,
  id = null,
  title = "ANEXAR FICHEIRO",
  files = null,
  disabled,
  edit,
  apiEndpoint,
  entityParamFirst = true,
}) => {
  const { keycloak } = useKeycloak();
  const [file, setFile] = useState(null);

  const getFile = async (id, name, set) => {
    try {
      const endpoint = entityParamFirst
        ? `/find/${id}/${name}`
        : `/find/${name}/${id}`;

      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${apiEndpoint}${endpoint}`,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );

      console.log("Resposta da Requisição:", response);
      setFile(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFile(id, name, setFile);
  }, [keycloak.token, id, name]);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (!edit && file) {
      // Verifica se há um 'id' antes de tentar o download
      if (file.id) {
        window.open(`${apiEndpoint}/download/${file.id}`, "_blank");
      } else {
        if (files && files[name]) return;
        fileInputRef.current.click();
      }
    } else {
      if (files && files[name]) return;
      fileInputRef.current.click();
    }
  };

  return (
    <StyledButton
      variant="contained"
      sx={{
        ...(!file && disabled ? { pointerEvents: "none", opacity: 0.5 } : {}),
        ...((file || (files && files[name])) && {
          border: "none",
          color: "#808080",
          backgroundColor: "#E9ECF5",
        }), // Cor cinza para o texto e fundo quando há arquivo
        "&:hover": {
          backgroundColor: "transparent",
          color: "#FFC107",
        },
      }}
      onClick={() => handleButtonClick()}
    >
      <PictureAsPdfIcon style={{ marginRight: "8px", color: "#FFC107" }} />
      {!edit && file && file.name
        ? file.name
        : files && files[name]
        ? files[name]?.name
        : title}
      {((file && !file.id) || (files && files[name])) && (
        <CloseIcon
          aria-label="Excluir arquivo"
          onClick={() => {
            setFile(null);
            set({ ...files, [name]: null });
          }}
        >
          X
        </CloseIcon>
      )}
      <input
        ref={fileInputRef}
        name={name}
        type="file"
        onChange={(e) => {
          let selectedFile = e.target.files[0];
          files[name] = selectedFile;
          set({ ...files });
          setFile(selectedFile); // Atualiza o estado com o arquivo selecionado
        }}
        style={{ display: "none" }}
      />
    </StyledButton>
  );
};
UploadButton.propTypes = {
  set: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  files: PropTypes.object,
  disabled: PropTypes.bool,
  edit: PropTypes.bool,
  apiEndpoint: PropTypes.string.isRequired,
  entityParamFirst: PropTypes.bool,
};

export default UploadButton;
