import React, { createContext } from "react";
import PropTypes from "prop-types"; // Importe o PropTypes para realizar a validação das props
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from '@react-keycloak/web';

const AuthContext = createContext();

function AuthProvider({ children }) {
  return (
    <ReactKeycloakProvider initOptions={{}} authClient={keycloak}>
      {children}
    </ReactKeycloakProvider>
  );
}

// Adicione a validação das props utilizando PropTypes
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired // Garante que a propriedade children seja passada e seja um nó React válido
};

export default AuthProvider;
