// AlertDialogForConfirm.js

import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { i18n } from "../../translate/i18n";
import FallBackLoader from "../FallBackComponents/FallBackLoader";
import PropTypes from 'prop-types';

const AlertDialogForConfirm = ({
  id,
  open,
  handleClose,
  handleDeleteItem,
  message = i18n.t("messages.confirmDelete"),
  isSuccessful = false,
  isLoading = false,
}) => {
  console.log("Rendering AlertDialogForConfirm", open);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{i18n.t("others.delete")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FallBackLoader isLoading={isLoading} />
          {!isLoading && message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isLoading}
          sx={{ padding: 0.5 }}
        >
          {i18n.t("others.cancel")}
        </Button>
        <Button
          onClick={() => handleDeleteItem(id)}
          color="primary"
          disabled={isLoading || isSuccessful}
          sx={{ padding: 0.5 }}
        >
          {i18n.t("others.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
AlertDialogForConfirm.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  message: PropTypes.string,
  isSuccessful: PropTypes.bool,
  isLoading: PropTypes.bool,
};


export default AlertDialogForConfirm;
