import { Grid, Typography } from "@mui/material";
import axios from "axios";
import "./styles/secondary-school.css";
import Textfield from "../../../components/form-components/text-field";
import Select from "../../../components/form-components/select";
import UploadButton from "../../../components/form-components/upload-button";
import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { useEffect } from "react";

const options = [
  {
    name: "Escola do Ensino Secundário do II Ciclo",
    id: "Escola do Ensino Secundário do II Ciclo",
  },
  {
    name: "Escola do Ensino Técnico-Profissional",
    id: "Escola do Ensino Técnico-Profissional",
  },
  {
    name: "Escola de Formação de Professores",
    id: "Escola de Formação de Professores",
  },
];

const SecondarySchoolInfo = ({
  id,
  setFiles,
  files,
  disabled,
  edit,
}) => {
  const { keycloak } = useKeycloak();
  const [financingTypes, setFinancingTypes] = useState([]);
  const [studentRegime, setStudentRegime] = useState([]);
  const [municipe, setMunicipe] = useState([]);
  const [schoolProvenances, setSchoolProvenances] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token, // token de autenticação
        },
      });
      console.log("Resposta da Requisição:", response);
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList("school-provenances", setSchoolProvenances);
    getList("financing-types", setFinancingTypes);
    getList("student-regime-types", setStudentRegime);
    getList("municipe", setMunicipe);
  }, []);
  return (
    <div className="secondary-school">
      {disabled ? null : (
        <>
          <h2>Inscreva-se</h2>
          <Typography
            sx={{ textAlign: "justify", color: "#949494" }}
            variant="p"
          >
            É de extrema importância que forneça com precisão a informação
            relacionada ao ensino médio.
          </Typography>
        </>
      )}
      <Typography
        sx={{ paddingTop: "38px", paddingBottom: "20px", color: "#003B76" }}
        variant="h3"
      >
        Dados do Ensino Médio
      </Typography>

      <Grid container spacing={4}>
        <Grid item sm={12} md={5} xs={8}>
          <Select
            disabled={disabled}
            name="schoolOrigen"
            options={options}
            label={
              <span>
                Procedência da Escola do Ensino Médio*
                <span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={12} md={5} xs={8}>
          <Select
            disabled={disabled}
            name="natureoftheschoolofprovenance"
            options={schoolProvenances}
            label={
              <span>
                Natureza da Escola de Proveniência
                <span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} style={{ marginTop: "10px" }}>
        <Grid item sm={12} md={5} xs={8}>
          <Textfield
            disabled={disabled}
            name="nameOfTheHighSchoolCourse"
            label={
              <span>
                Nome do Curso do Ensino Médio
                <span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={6} md={2.5} xs={4}>
          <Textfield
            disabled={disabled}
            name="highSchoolFinalGrade"
            label={
              <span>
                Nota final do Ensino Médio
                <span className="red-asterisk">*</span>
              </span>
            }
            type="number"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} style={{ marginTop: "10px" }}>
        <Grid item sm={12} md={5} xs={8}>
          <Select
            disabled={disabled}
            name="studyFinancing"
            options={financingTypes}
            label={
              <span>
                Financiamento dos estudos<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={12} md={5} xs={8}>
          <Select
            disabled={disabled}
            name="studentRegime"
            options={studentRegime}
            label={
              <span>
                Regime Estudantil<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} style={{ marginTop: "10px" }}>
        <Grid item sm={12} md={5} xs={8}>
          <p
            style={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#42474B",
              margin: "0px",
            }}
          >
            <span>
              Certificado do curso médio<span className="red-asterisk">*</span>
            </span>
          </p>

          <UploadButton
            edit={edit}
            disabled={disabled}
            id={id}
            set={setFiles}
            name="HighSchoolCertificate"
            files={files}
            apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
            entityParamFirst={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SecondarySchoolInfo;
