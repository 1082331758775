import { useDraggable } from "@dnd-kit/core";
import { List, Paper } from "@mui/material";

const DraggableItem = ({ id, name }) => {
    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
      id: id,
    });
  
    const style = {
      transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
      background: isDragging ? '#e3f2fd' : '#E9ECF5',
      padding: 8,
      margin: 8,
      display: "flex",
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'grab',
      fontWeight: 'bold'
    };
  
    return (
      <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
        {name}
      </div>
    );
};

export default DraggableItem