import { useState, useCallback } from "react";
import { i18n } from "../../translate/i18n";
import { getErrorMessage, handleFailure } from "../functions/utilFunctions";

export function useConfirmDeleteModal() {
  const [open, setOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [message, setMessage] = useState(i18n.t("messages.confirmDelete"));
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = useCallback(() => {
    setDeleteItemId(null);
    setOpen(false);
    setMessage(i18n.t("messages.confirmDelete"));
    setLoading(false);
    setSuccess(true);
  }, []);

  const handleClickOpen = useCallback((id) => {
    setDeleteItemId(id);
    setOpen(true);
    setSuccess(false);
  }, []);

  const handleSuccess = useCallback((time = 500) => {
    setMessage(i18n.t("messages.success"));
    setSuccess(true);
    setLoading(false);
    setTimeout(() => {
      handleClose();
    }, time);
  }, []);

  const handleBadRequest = useCallback((response, time = 4000) => {
    setMessage(handleFailure(response));
    setSuccess(false);
    setLoading(false);
    setTimeout(() => {
      handleClose();
    }, time);
  }, []);

  const errorFallback = useCallback((error, time = 4000) => {
    setMessage(getErrorMessage(error));
    setSuccess(false);
    setLoading(false);
    setTimeout(() => {
      handleClose();
    }, time);
  }, []);

  return {
    open,
    deleteItemId,
    message,
    loading,
    setLoading,
    success,
    handleClose,
    handleClickOpen,
    handleSuccess,
    handleBadRequest,
    errorFallback,
  };
}

export const useMessageModal = () => {
  const [open, setOpen] = useState(false);
  const [changeState, setChangeState] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    setChangeState(null);
    setOpen(false);
    setMessage("");
    setLoading(false);
  }, []);

  const handleClickOpen = useCallback(() => {
    setChangeState(true);
    setOpen(true);
  }, []);

  const handleSuccess = useCallback((time = 500) => {
    setMessage(i18n.t("messages.success"));
    setLoading(false);
    setTimeout(() => {
      handleClose();
    }, time);
  }, []);

  const handleBadRequest = useCallback((response, time = 4000) => {
    setMessage(handleFailure(response));
    setLoading(false);
    setTimeout(() => {
      handleClose();
    }, time);
  }, []);

  const errorFallback = useCallback((error, time = 4000) => {
    setMessage(getErrorMessage(error));
    setLoading(false);
    setTimeout(() => {
      handleClose();
    }, time);
  }, []);

  return {
    open,
    message,
    loading,
    setLoading,
    changeState,
    handleClose,
    handleClickOpen,
    handleSuccess,
    handleBadRequest,
    errorFallback,
  };
};
