import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import PropTypes from 'prop-types';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedOptions, theme) {
  return {
    fontWeight:
      selectedOptions?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({
  options,
  label,
  selectedOptions,
  setSelectedOptions,
}) {
  const theme = useTheme();

  const handleClick = (event) => {
    const selected = event.target.value;

    const filteredOptions = options.filter((option) =>
      selected.includes(option)
    );
    console.log(filteredOptions);
    setSelectedOptions(filteredOptions);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={selectedOptions}
          onChange={handleClick}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          {options?.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, selectedOptions, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
MultipleSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired, // options como array de strings obrigatório
  label: PropTypes.string.isRequired, // label como string obrigatória
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired, // selectedOptions como array de strings obrigatório
  setSelectedOptions: PropTypes.func.isRequired, // setSelectedOptions como função obrigatória
};
