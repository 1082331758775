import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide } from "@mui/material";
import React from "react";
import { i18n } from "../../../translate/i18n";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalConfirmation = (props) => {

  const { head, documentName, handleConfirm, handleClose, open, } = props;



  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          position: "absolute",
          top: "20%",
          bottom: "40%",

        }}
      >
        <IconButton onClick={handleClose}
          sx={{
            position: "absolute",
            marginLeft: "240px",
          }}>
          <img src="/icon/close.svg" alt="Close" style={{ width: 35.96, height: 35.96 }} />
        </IconButton>
        <DialogTitle sx={{ color: "#003B76", textAlign: "center", paddingTop: "5px" }}>
          {head}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que quer solicitar 
            <p style={{ color: "#003B76" }}>{documentName}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingBottom: "30px" }}>
          <Button
            variant="outlined"
            sx={{
              height: "25px",
              color: "#FFC107", // Definindo a cor do texto como amarelo
              backgroundColor: "#FFFFFF",
              borderColor: "#FFC107",
              "&:hover": {
                color: "#FFC107",
                backgroundColor: "#FFFFFF",
                borderColor: "#FFC107",
                // Adicione outros estilos de hover conforme necessário
              },
            }}
            onClick={handleClose}>
            {i18n.t("button.cancel")}
          </Button>
          <Button
            variant="outlined"
            sx={{
              height: "25px",
              color: "#FFFFFF", // Definindo a cor do texto como amarelo
              backgroundColor: "#FFC107",
              borderColor: "#FFC107",
              "&:hover": {
                color: "#FFFFFF",
                backgroundColor: "#FFC107",
                borderColor: "#FFC107",
                // Adicione outros estilos de hover conforme necessário
              },
            }}
            onClick={handleConfirm}>
            {i18n.t("confirmar")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ModalConfirmation;