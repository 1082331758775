import { Box, Grid, Paper, Table, styled, tableCellClasses, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { i18n } from "../../../translate/i18n";
import AutoSearch from "../../../components/autoSearch/AutoSearch";
import FilterList from "../../../components/filterList/FilterList";
import { useEffect, useState } from "react";
import ActionButtons from "../../../components/actions-buttom/ActionButtons";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F8FAFD",
      color: "#939a9f",
    },
    [`&.${tableCellClasses.body}`]: {},
}));

const solveStatus = (val) => {
    switch (val) {
      case "AWAITING_PAYMENT":
        return "Gerar RUPE";
      case "RUPE_GENERATED":
        return "RUPE Gerado";
      case "PAYMENT_REJECTED":
        return "Pagamento Rejeitado";
      case "PAID":
        return "Pago";
      case "LIQUIDATED":
        return "Liquidado";
   
      case "ISSUED":
        return "Enviado";
      default:
        return "Indeterminado";
    }
};


const IssueDocumentSEListingPage = () => {
    const [search,setSearch] = useState("");
    const { keycloak } = useKeycloak();
    const [roles, setRoles] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const [incomingDate, setIncomingDate] = useState([]);
    const navigate = useNavigate();
    const issueDocumenSEtAnalyse = (id) => {
        navigate(`/issuedocument-Analyse/${id}`);
    }

    const getEnrolls = async () => {
      try {
        const response = await axios.get(`${apiUrl}/student-document-issuance`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });
        setIncomingDate(response.data?.data);
        console.log(incomingDate)

      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (keycloak.token) {
        getEnrolls();
      }
      if (keycloak.tokenParsed) {
        setRoles(keycloak.tokenParsed.realm_access.roles);
      }
    }, [keycloak.token]);

    return (
        <Grid container component={Paper}>
            <Grid item xs={12}>
                <div className="container-da-tabela-responsiva">
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 650, border: "1px solid #E9ECF5" }}
                            size="small"
                            aria-label="a dense table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" colSpan={6} style={{ height: "59px" }}>
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                        >
                                            {i18n.t("Emissão de Documentos")}
                                            <Box sx={{ display: "flex", gap: "10px" }}>
                                                <Box sx={{ width: "200px" }}>
                                                  <AutoSearch placeholder={i18n.t("others.search")} setSearch={setSearch} />
                                                </Box>               
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "120px",
                                                    height: "40px",
                                                    border: "1px solid #E9ECF5",
                                                  }}
                                                >
                                                  <FilterList
                                                    cardStyle={{
                                                      position: "absolute",
                                                      indez: "1",
                                                      top: "140px",
                                                      right: "323px",
                                                    }}
                                                  />
                                                  {i18n.t("others.filter")}
                                                </Box>
                                                </Box>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow height="56px" sx={{ backgroundColor: "#F8FAFD" }}>
                                    <StyledTableCell align="left">{i18n.t("Nº Mecanografico")}</StyledTableCell>
                                    <StyledTableCell align="left">{i18n.t("others.name")}</StyledTableCell>
                                    <StyledTableCell align="left">{i18n.t("others.documents")}</StyledTableCell>
                                    <StyledTableCell align="left">{i18n.t("others.date")}</StyledTableCell>
                                    <StyledTableCell align="left">{i18n.t("others.status")}</StyledTableCell>
                                    <StyledTableCell align="center">{i18n.t("others.options")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {incomingDate.map((enroll) => (
                                        <TableRow
                                            key={enroll.id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                            <TableCell component="th" scope="row">
                                              {enroll.student?.mechanographicNumber}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {enroll.student?.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {enroll.documentId?.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {enroll.createdDate}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {solveStatus(enroll.status)}
                                            </TableCell>
                                            <TableCell align="left">
                                              <ActionButtons
                                                id={enroll.id}
                                                handleVisualize={issueDocumenSEtAnalyse}
                                              />
                                            </TableCell>
                                            </TableRow>
                                        ))
                                }                  
                            </TableBody>
                        </Table>
                    </TableContainer>
                 
                </div>
            </Grid>
        </Grid>
    )
}

export default IssueDocumentSEListingPage;